import React, { useEffect, useState } from 'react';
import { fetchAllTrades } from '../apiAdmin';
import { isAuthenticated } from '../../auth';
import {Link} from 'react-router-dom';
import Layout from '../../core/Layout';

const AllTrades = () => {
  const [trades, setTrades] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    fetchAllTrades(_id, token)
      .then(data => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTrades(data);
        }
      })
      .catch(err => console.error("Error fetching eBay messages:", err));
  }, [_id, token]);


  const renderDate = (createdAt) => {
    var date = new Date(createdAt);
    return <p>{((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()}</p>
  };

  return (
    <Layout>
      <div>
        {trades.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {trades.map((trade) => (
              <Link
                to={`/trade-messages/${trade._id}`}
              >
                <li key={trade._id} className="px-4 py-4 sm:px-6">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-indigo-600">
                        Sender: {trade.sender.name} {trade.sender.isFromEbay && (<p className='text-blue-600'>EBAY ACCOUNT</p>)}

                      </p>
                      <p className="text-sm font-medium text-green-600">
                        Receiver: {trade.receiver.name} {trade.receiver.isFromEbay && (<p className='text-blue-600'>EBAY ACCOUNT</p>)}
                      </p>
                    </div>
                    <div>
                      Status: {trade.status}
                    </div>
                    <div>
                      {renderDate(trade.createdAt)}
                    </div>
                    <div>
                      <p>{trade.receiver.name}'s items {JSON.stringify(trade.receiverItems.map(item => item.name))}</p>
                      <p>{trade.sender.name}'s items {JSON.stringify(trade.senderItems.map(item => item.name))}</p>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        ) : (
          <p>No eBay trades found.</p>
        )}
      </div>
    </Layout>
  );
};

export default AllTrades;

