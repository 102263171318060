//TODO: move all relatant api calls from apiUser here
import { signout } from '../../auth';
import { API } from '../../config';
const { v1: uuidv1 } = require('uuid');

export const getSignedRequest = (file) => {
    const newName = uuidv1()+encodeURIComponent(file.name);
    //console.log(newName);
    return fetch(`${API}/sign-s3?file-name=${newName}&file-type=${file.type}`, {
        method: 'GET',
    })
        .then(response => {
            return response.json(); //then call uploadFile in front end
        })
        .catch(err => console.log(err));
};

export const uploadFile = (file, signedRequest, url) => {
    console.log(file)
    return fetch(signedRequest, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
        },
        body: file
    })
        .then(response => {
            console.log('signedrequest', signedRequest);
            if (response.status === 200) {
                return url;
            }
            else {
                return {error: 'Could not return back signed url'};
            }
        })
        .catch(err => console.log(err));
};

