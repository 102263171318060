import React, { useEffect, useState } from 'react';
import { fetchEbayTrades } from '../apiAdmin';
import { isAuthenticated } from '../../auth';
import {Link} from 'react-router-dom';
import Layout from '../../core/Layout';

const EbayTrades = () => {
  const [trades, setTrades] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    fetchEbayTrades(_id, token)
      .then(data => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTrades(data);
        }
      })
      .catch(err => console.error("Error fetching eBay messages:", err));
  }, [_id, token]);


  return (
    <Layout>
      <div>
        {trades.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {trades.map((trade) => (
              <Link
                to={`/trade/${trade._id}`}
              >
                <li key={trade._id} className="px-4 py-4 sm:px-6">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-indigo-600">
                        Sender: {trade.sender.name}
                      </p>
                      <p className="text-sm font-medium text-green-600">
                        Receiver: {trade.receiver.name}
                      </p>
                    </div>
                    <div>
                      {trade?.receiver?.isFromEbay && trade.messages.length > 0 && trade.receiverNewMessage === true && (
                        <p className='text-red-600'>NEW MESSAGE!</p>
                      )}
                      {trade?.sender?.isFromEbay && trade.messages.length > 0 && trade.senderNewMessage === true && (
                        <p className='text-red-600'>NEW MESSAGE!</p>
                      )}
                      <p>{trade.messages.length > 0 ? trade.messages[trade.messages.length-1].message : ''}</p>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        ) : (
          <p>No eBay trades found.</p>
        )}
      </div>
    </Layout>
  );
};

export default EbayTrades;
