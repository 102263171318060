import React from 'react';
import Header from './header/Header';
import Footer from './Footer';

const Layout = ({title="Title", description="Description", className, children}) => (
    <div>
        <Header />
        <div className={className}>{children}</div>
        <Footer/>
    </div>
);

export default Layout;
