import Icon from '../images/icon.png';

const Loading = () => (
    <>
        <div className='h-screen object-none object-center '>
            <div className='max-w-screen-lg mx-auto flex justify-center object-center'>
            <img
                className='h-24 w-24 rounded-full flex justify-center animate-pulse'
                src={Icon}
                alt='loading'
            />
            </div>
            <p className='flex-1 font-bold flex justify-center animate-pulse'> Loading... </p>
        </div>
    </>
);

export default Loading;
