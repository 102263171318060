import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../auth';
import { Link, useParams } from 'react-router-dom';
import { getAllItems } from '../apiAdmin';
import Layout from '../../core/Layout';

const AllUserItems = () => {
  const [items, setItems] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const {user: { _id }, token } = isAuthenticated();

  useEffect(() => {
    getAllItems(_id, token).then(res => setItems(res));
  }, []);

  const handleFilter = (filter) => {
    setItems([]);
    setFilterType(filter);
    getAllItems(_id, token).then(res => {
      if (filter === 'all') {
        setItems(res);
        return;
      }
      if (filter === 'sellable') {
        let newItems = res.filter(item => item.type === 'trade-sell' || item.type === 'sell-only');
        setItems(newItems);
        return;
      }
      let newItems = res.filter(item => item.type === filter);
      setItems(newItems);
      });
  };

  return (
    <Layout>
      <select
        onChange={e => handleFilter(e.target.value)}
        value={filterType}
      >
        <option value='trade-sell'>Trade & Sell</option>
        <option value='sellable'>Sellable</option>
        <option value='trade-only'>Trade Only</option>
        <option value='sell-only'>Sell Only</option>
        <option value='all'>All</option>
      </select>
        {items.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {items.map((item) => (
                <li key={item._id} className="px-4 py-4 sm:px-6">
                  <div className="flex justify-between">
                    <div>
                      <Link
                        to={`/loot/${item._id}`}
                        target="_blank"
                      >
                      <p className="text-sm font-medium text-indigo-600">
                        {item.name}
                      </p>
                      <img
                        className='h-32 w-32 object-cover'
                        src={item.primary_photo}
                        alt="wassup"
                      />
                      <p>{item.type}</p>
                      </Link>
                    </div>
                    <div>
                      {item._id}
                      <p>{item.createdAt}</p>
                    </div>
                    <div>
                      <p>{item.userId.name}</p>
                      <p>${item?.price}</p>
                      <p>Price Drops: {JSON.stringify(item?.priceDrops)}</p>
                    </div>
                  </div>
                </li>
            ))}
          </ul>
        ) : (
          <p>loading....</p>
        )}
    </Layout>
  );
};

export default AllUserItems;
