import { API } from '../config';

export const getOrderFromTracking = (trackingNum) => {
    return fetch(`${API}/order-from-tracking/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({trackingNum})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//verifyier user id used for auth
export const acceptVerify = (orderId, verifierUserId, userId, token) => {
    return fetch(`${API}/accept-verify/${verifierUserId}/${orderId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userId})

    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const denyVerify = (orderId, verifierUserId, userId, token) => {
    return fetch(`${API}/deny-verify/${verifierUserId}/${orderId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userId})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const setNewTracker = (orderId, verifierUserId, userId, tracker, carrier, token) => {
    return fetch(`${API}/set-new-tracker/${verifierUserId}/${orderId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
      body: JSON.stringify({userId: userId, tracker: tracker, carrier: carrier})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const getUnverifiedProducts = (userId, token, page, ITEMS_PER_PAGE) => {
    var skip = page * ITEMS_PER_PAGE;
    return fetch(
  `${API}/get-unverified-products/${userId}?skip=${skip}&limit=${ITEMS_PER_PAGE}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const virtuallyVerify = (userId, token, productUserId, productId, notifyAll) => {
    return fetch(`${API}/virtually-verify/${userId}/${productId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userId: productUserId, notifyAll })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const virtuallyDecline = (userId, token, productUserId, message, productId) => {
    return fetch(`${API}/virtually-decline/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userId: productUserId, message, productId })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const submitBanUser = (userId, token, bannedUserId, banReason) => {
  return fetch(`${API}/ban-user/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId: bannedUserId,
      banReason,
    }),
  })
    .then(response => {
      return response.json();
    })
};

export const deleteProduct = (userId, token, productId) => {
  return fetch(`${API}/product/${productId}/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      return response.json();
    })
};

export const bulkVerify = (userId, token, productIds) => {
  console.log(productIds);
  return fetch(`${API}/bulk-verify/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({productIds}),
  })
    .then(response => {
      return response.json();
    })
};

export const bulkDelete = (userId, token, productIds) => {
  console.log(productIds);
  return fetch(`${API}/bulk-delete/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({productIds}),
  })
    .then(response => {
      return response.json();
    })
};


export const getInvalidEbayItems = (userId, token) => {
  return fetch(`${API}/invalid-ebay-items/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      return response.json();
    })
};
