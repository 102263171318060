import { API } from '../config';

/*
* User Info
*/
//dont need the token anymore but ill leave it so it doenst break other code
export const getUserInfo = (userId) => {
    return fetch(`${API}/user/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getDetailedUserInfo = (userId) => {
     return fetch(`${API}/detailed-user-info/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err)); 
};

export const newNotifFalse = (userId, token) => {
    return fetch(`${API}/user/new-notif-false/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/*
 * Shipping
 */
export const addShippingAddr = (userId, token, address) => {
    return fetch(`${API}/user/${userId}/update-shipping`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({addr: address})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

/*
 * Products
 */
export const getListedProducts = (userId, token) => {
    return fetch(`${API}/user/${userId}/listings`, {
        method: 'GET',
        headers:{
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//No longer need token but keeping it so it doenst break other code
export const getMyListedItems = (userId) => {
    return fetch(`${API}/user/${userId}/my-listed-items`, {
        method: 'GET',
        headers:{
            "Content-Type": 'application/json',
        }
    })
        .then(response => {
            console.log('RESPONSE'+response);
            return response.json();
        })
        .catch(err => console.log(err));

};


export const createProduct = (userId, token, product) => {
    return fetch(`${API}/product/create/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateProduct = (productId, userId, token, product) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({product: product})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProduct = productId => {
    return fetch(`${API}/product/${productId}`, {
        method: 'GET'
    })
        .then (response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const findManyProducts = (productIds) => { //TODO: organize api calls into respective folders
    return fetch(`${API}/find-many-products`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({productIds: productIds})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const deleteProduct = (productId, userId, token) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
/* 
 * Categories
 */
export const getCategories = () => {
    return fetch(`${API}/categories`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


/*
 * Trades
 */
export const getTrade = (tradeId, userId, token) => {
    return fetch(`${API}/trade/${tradeId}/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })

        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const cancelTrade = (userId, tradeId, token) => {
    return fetch(`${API}/cancel-trade/${userId}/${tradeId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const getActiveTrades = (userId, token) => {
    return fetch(`${API}/trades/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })

        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//handles a accepted trade that has only a money offer
export const acceptMoneyOfferTrade = (userId, tradeId, token) => {
    return fetch(`${API}/accept-money-offer-trade/${userId}/${tradeId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const saveMessages = (content, userId, tradeId, token) => {
    return fetch(`${API}/save-messages/${userId}/${tradeId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(content)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));

};

/*
 * Orders
 */
export const createOrder = (userId, tradeId, token) => {
    return fetch(`${API}/order/create/${userId}/${tradeId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getMyOrders = (userId, token) => {
    return fetch(`${API}/order/${userId}/my-orders`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getOrder = (orderId) => {
    return fetch(`${API}/order/get-order/${orderId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const receiverUpdateOrder = (orderId, userId, token, formData) => {
    return fetch(`${API}/order/receiver-update/${userId}/${orderId}`, {
        method: 'PUT',
        header: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
        body: formData
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const senderUpdateOrder = (orderId, userId, token, formData) => {
    return fetch(`${API}/order/sender-update/${userId}/${orderId}`, {
        method: 'PUT',
        header: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
        body: formData
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateStatus = (orderId, userId, token, newStatus) => {
    return fetch(`${API}/order/update-status/${userId}/${orderId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({status: newStatus})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const savePaypal = (userId, paypalInfo, token) => {
    return fetch(`${API}/save-paypal/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({paypalInfo: paypalInfo})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const newMessageFalse = (tradeId, token, isReceiver) => {
    return fetch(`${API}/new-message-false/${tradeId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({isReceiver})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
