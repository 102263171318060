import React, { useState, useRef, Fragment  } from 'react';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { GOOGLE_CLIENT_ID } from '../config';
import { XCircleIcon } from '@heroicons/react/24/solid';
import {  authenticate, isAuthenticated, signIn } from '../auth';
// import for password box
import { EyeSlashIcon } from '@heroicons/react/20/solid'
import { EyeIcon } from '@heroicons/react/20/solid'


const SigninModal = ({ open, handleClose, handleOpenSignup, guest=false}) => {
    const { productId } = useParams();

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        error: '',
        loading: false,
        redirectToReferrer: false,
    });

    const cancelButtonRef = useRef(null);

    const handleOpen = () => {
        handleClose();
    };

    const { email, password, showPassword, error, loading, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({...values, error:false, [name]: event.target.value});
    };

    const handlePasswordShow = () => setValues({...values, showPassword: !showPassword});
    

    const clickSubmit = event => {
        event.preventDefault();
        setValues({...values, error: false, loading: true});

        signIn({email, password})
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error, loading: false});
                }
                else {
                    authenticate(data, () => {
                        setValues({
                            ...values,
                        });
                        handleClose();
                    });
                }
            });
    };


    const showError = () => (
      <div className="rounded-md bg-red-50 p-4" style={{display: error ? '' : 'none'}}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <div className="mt-2 text-sm text-red-700">
              <p className='pl-5 space-y-1' data-testid='signin-error'> {error} </p>
          </div>
        </div>
      </div>
    </div>
    
    );


   return (
    <form className='absolute z-50'>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-8 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 ">
              <div>
                <div className="mt-1 text-center sm:mt-2">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900" data-testid='signin-title'>
                      Sign in
                  </Dialog.Title>
                    <button className='text-indigo-500 text-sm' onClick={handleOpenSignup}> or create an account </button>
                </div>
              </div>

               {showError()}

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-2">
                  Email address
                </label>
                <div className="">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange('email')}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-2">
                  Password
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="password"
                    name="password"
                    onChange={handleChange('password')}
                    type={showPassword ? "text" : "password"}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                    <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                        <button
                            onClick={handlePasswordShow}
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        >
                            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                        </button>
                    </div>
                </div>
              </div>

                <div className="text-sm">
                  <a href="/password-reset-request" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={clickSubmit}
                >
                  Sign in
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleClose}
                  ref={cancelButtonRef}
                  data-testid='signin-cancel'
                >
                  Cancel
                </button>
              </div>

                {guest &&
                <a href={`/guest-checkout/${productId}`}>
                  <button
                      className='text-sm font-medium text-indigo-600 mt-2'
                  > 
                      Continue as guest 
                  </button>
                </a>
                }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    </form>
    );
};

export default SigninModal;
