import { API } from '../config';
import queryString from 'query-string';

export const startTrade = (selectedItems, itemToTrade, token, _id) => { //selectedItems == sender selected items
    console.log('selectedItems');
    console.log(itemToTrade._id);
    const data = {
        'receiver': itemToTrade.userId,
        'sender': selectedItems.senderId,
        'receiverItem': itemToTrade._id,
        'senderItems': selectedItems.productIds,
        'senderMoneyOffer': selectedItems.senderMoneyOffer
    };

    return fetch(`${API}/start-trade/${_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch( err => console.log(err));
};

export const list = params => {
    const query = queryString.stringify(params);
    return fetch(`${API}/listed-products?${query}`, {
            method: "GET"
            })  
        .then(response => {
                        return response.json();
                    })  
        .catch(err => console.log(err));

};

export const getListed = (sortBy) => {
        return fetch(`${API}/listed-products?sortBy=${sortBy}&order=desc&limit=6`, {
                method: "GET"
                })  
            .then(response => {
                            return response.json();
                        })  
            .catch(err => console.log(err));
};


export const getCategories = () => {
        return fetch(`${API}/categories`, {
                method: "GET"
                })  
            .then(response => {
                            return response.json();
                        })  
            .catch(err => console.log(err));
};

export const getFilteredProducts = (skip, limit, filters = {}) => {
    const data = {
        limit, skip, filters
    };
    return fetch(`${API}/products/by/search`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },  

        body: JSON.stringify(data)
    })  
        .then(response => {
            return response.json();
        })  
        .catch(err => {
            console.log(err);
        }); 
};

export const getFilteredProductsExceptUser = (userId, skip, limit, filters = {}) => {
    const data = {
        userId, limit, skip, filters
    };
    return fetch(`${API}/products/by/search-except-user`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },  

        body: JSON.stringify(data)
    })  
        .then(response => {
            return response.json();
        })  
        .catch(err => {
            console.log(err);
        }); 
};
 
export const saveNotification = (content, userId, token) => {
    return fetch(`${API}/user/save-notification/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(content)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteNotification = (content, userId, token) => {
    return fetch(`${API}/user/delete-notification/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            
        },
        body: JSON.stringify(content)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const requestPasswordReset = (requestedEmail) => {
    return fetch(`${API}/request-password-reset`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({requestedEmail: requestedEmail})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const verifyPasswordReset = (token, email) => {
    return fetch(`${API}/verify-password-reset`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: token, email: email})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const resetPassword = (token, email, new_pass) => {
    return fetch(`${API}/reset-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: token, email: email, new_pass: new_pass})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

export const verifyEmail = (token, userId) => {
    return fetch(`${API}/verify-email`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token, userId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const resendVerifyEmail = (email, userId, token) => {
    return fetch(`${API}/resend-verify-email/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({email, userId})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};
