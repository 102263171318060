import React from 'react';

const Badges = ({type}) => {
    if (type === 'trade-sell') {
        return (
            <>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            Trade
          </span>
          <span className="inline-flex items-center px-3.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            Sell
          </span>
            </>
        );
    }
    else if (type === 'trade-only') {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            Trade only
          </span>
        );
    }
    else if (type === 'sell-only') {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            Sell only
          </span>
        );
    }
    else {
        return (
            <></>
        );
    }
};

export default Badges;
