import React, { useEffect, useState } from 'react';
import Layout from '../../core/Layout';
import { isAuthenticated } from '../../auth';
import { fetchUserItemData } from '../apiAdmin';
import { upperClothingSize, lowerClothingSize, shoesSizeList, brandsList } from './sizeConstants';

const UserItemData = () => {
  const [userData, setUserData] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  const [size, setSize] = useState('any');
  const [brand, setBrand] = useState('any');
  const [type, setType] = useState('likedProducts');

  const handleSubmit = () => {
    fetchUserItemData(_id, token, size, brand, type).then(res => {
      setUserData(res);
      console.log('res', res);
    });
  };

  const headers = [
    { label: "User Name", key: "userName" },
    { label: "Shoe Sizes", key: "shoeSizes" },
    { label: "Tops Sizes", key: "topsSize" },
    { label: "Bottoms Sizes", key: "bottomsSize" },
    { label: "Favorite Brands", key: "favoriteBrands" },
    { label: "Condition Interest", key: "conditionInterest" },
  ];

  return (
    <Layout>
      <div>
        <h3>Select Size</h3>
        <select
          id="size"
          onChange={e => setSize(e.target.value)}
          value={size}
          name="size"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          {
            ['any', ...shoesSizeList, ...lowerClothingSize, ...upperClothingSize]
              .map(sizeData => (
                <option value={sizeData.value}>{sizeData.value}</option>
              ))
          }
        </select>

        <h3>Select Brand</h3>
        <select
          id="brand"
          onChange={e => setBrand(e.target.value)}
          value={brand}
          name="brand"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          {
            ['any', ...brandsList]
              .map(brandData => (
                <option value={brandData.value}>{brandData.value}</option>
              ))
          }
        </select>
        <h3>Select Type</h3>
        <select
          id="type"
          onChange={e => setType(e.target.value)}
          value={type}
          name="type"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option value='likedProducts'>Liked Products</option>
          <option value='recentlyViewed'>Item View History</option>
        </select>

        <button onClick={handleSubmit} className='bg-green-600 text-white p-3'>Submit</button>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header.key} className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {userData.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.name}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.shoeSizes}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.topsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.bottomsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.favoriteBrands}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.conditionInterest}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default UserItemData;
