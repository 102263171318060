import React, { useState, useEffect, useRef, Fragment } from 'react';
import Header from './header/Header';
import Footer from './Footer';
import HitCard from './HitCard';
import { getCategories, getFilteredProducts, getFilteredProductsExceptUser } from './apiCore';
import { getUserInfo } from '../user/apiUser';
import { isAuthenticated } from '../auth';
import algoliasearch from 'algoliasearch';
import {
    Hits,
    connectSearchBox,
    connectRefinementList,
    Configure,
    connectInfiniteHits,
} from 'react-instantsearch-dom';
import { useInfiniteHits, InstantSearch, useSearchBox, useRefinementList } from 'react-instantsearch-hooks-web';
import FilterContainer from './FilterContainer';
import HeroSection from './HeroSection';
import CustomPriceRefinement from './CustomPriceRefinement';

//import { ALGOLIA_INDEX_NAME, ALGOLIA_APP_ID, ALGOLIA_API_KEY } from '../config'; //doesnt work for some reason
import { ALGOLIA_API_KEY, ALGOLIA_INDEX_NAME } from '../config';

//const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const appId = 'O616IHS8SQ';
//const ALGOLIA_INDEX_NAME = process.env.NODE_ENV === 'production' ? 'prod_lootswap' : 'dev_lootswap';

const searchClient = algoliasearch(appId, ALGOLIA_API_KEY);

const Listings = () => {
    const [myFilters, setMyFilters] = useState({
        filters: { category: [], price: [] }
    });
    const [categories, setCategories] = useState([])
    const [error, setError] = useState(false);
    const [limit, setLimit] = useState(6);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [filteredResults, setFilteredResults] = useState([]);

    const [likedProducts, setLikedProducts] = useState([]);

    const [activeTrades, setActiveTrades] = useState([]);
    const activeTradesRef = useRef();
    activeTradesRef.current = activeTrades;

    const handleChange = () => { //here for testing purposes
        console.log('nothing');
    };

    const loadFilteredResults = (newFilters) => {
        if (isAuthenticated()) {
            const {user: {_id}} = isAuthenticated();
            getFilteredProductsExceptUser(_id, skip, limit, myFilters.filters).then(data => {
                if (data.error) {
                    setError(data.error);
                }
                else {
                    setFilteredResults(data.data);
                    setSize(data.size)
                    setSkip(0);
                }
            });
        }
        else {
            getFilteredProducts(skip, limit, newFilters).then( data => {
                if (data.error) {
                    setError(data.error);
                }
                else {
                    setFilteredResults(data.data);
                    setSize(data.size)
                    setSkip(0);
                }
            });
        }
    };

    useEffect(() => {
        if (isAuthenticated()) {
            const { user: { _id } } = isAuthenticated();
            getUserInfo(_id).then(userRes => {
                setLikedProducts(userRes.likedProducts);
            });
        }
    }, []);


    const ShowRefinement = ({type}, props) => {
        if (type === 'Category') {
            return (
                <CustomCategoryRefinement attribute='category' {...props}/>
            );
        }
        else if (type === 'Brand') {
            return (
                <CustomBrandRefinement attribute='brand' {...props}/>
            );
        }
        else if (type === 'Size') {
            return (
                <CustomSizeRefinement attribute='size' {...props}/>
            );
        }
        else if (type === 'Price') {
            return (
                <div className='w-64'>
                    <CustomPriceRefinement attribute='price' {...props}/>
                </div>
            );
        };
    };
    //might use currentRefinement is useState to show current filters 
    const CustomCategoryRefinement = (props) => {
        const { items, refine } = useRefinementList(props);
        return (
            <div>
                <form>
                    {items.map((item,i) => (
                        <div key={item.label} className='flex items-center'>
                            <input
                                id={i}
                                name={i}
                                type='checkbox'
                                value={item.isRefined}
                                className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                                onChange={event => {
                                    refine(item.value)
                                }}
                            />

                            <label
                                htmlFor={i}
                                className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                            >
                                {item.label}
                            </label>
                        </div>
                    ))}
                </form>
            </div>
        );
    };



    const CustomSizeRefinement = (props) => {
        const { items, refine } = useRefinementList(props)

        return (
            <div>
                <form>
                    {items.map((item,i) => (
                        <div key={item.label} className='flex items-center'>
                            <input
                                id={i}
                                name={i}
                                type='checkbox'
                                value={item.isRefined}
                                className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                                onChange={event => {
                                    refine(item.value)
                                }}
                            />

                            <label
                                htmlFor={i}
                                className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                            >
                                {item.label}
                            </label>
                        </div>
                    ))}
                </form>
            </div>
        );
    };

    const CustomBrandRefinement = (props) => {
        const { items, refine, searchForItems } = useRefinementList(props)

        return (
            <div>
                <input
                    type='search'
                    placeholder='Search brand'
                    onChange={event => searchForItems(event.currentTarget.value)}
                    className='rounded-md text-xs w-full'
                />

                {items.map((item, i) => (
                    <div key={i} className='flex items-center'>
                        <input
                            id={i}
                            name={i}
                            type='checkbox'
                            value={item.isRefined}
                            className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                            onChange={event => {
                                console.log(event);
                                refine(item.value);
                            }}
                        />
                        <label
                            htmlFor={i}
                            className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                        >
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        );
    };
    
    const transformItems = items => {
        return items.filter(item => item.isVisible && item.isVirtuallyVerified);
    };

    const CustomHitsContainer = (props) => {
        const sentinelRef = useRef(null);
        const { hits, isLastPage, showMore } = useInfiniteHits({ ...props, transformItems });

        useEffect(() => {
            if (sentinelRef.current !== null) {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && !isLastPage) {
                            showMore();
                        }
                    });
                });

                observer.observe(sentinelRef.current);

                return () => {
                    observer.disconnect();
                };
            }
        }, [isLastPage, showMore]); 

        return (
            <div className='mx-auto px-4 sm:py-8 sm:px-6 lg:px-8'>
                <ul className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-x-2 sm:gap-y-5 lg:gap-x-3'>
                    {hits.map(hit => (
                        <li key={hit.objectID}>
                            <HitCard 
                                hit={hit} 
                                setLikedProducts={setLikedProducts}
                                likedProducts={likedProducts}
                            />
                        </li>
                    ))}
                    <li ref={sentinelRef} aria-hidden='true'/>
                </ul>
            </div>

        );
    };


    const CustomSearchBox = (props) => {
        const { query, refine } = useSearchBox(props);

        return (
            <form noValidate action='' role='search'>   
                <label for="default-search" class="text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                <div class="relative">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input 
                        value={query}
                        onChange={event => { refine(event.currentTarget.value); handleChange(event) }}
                        type="search" 
                        id="default-search" 
                        class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500" 
                        placeholder="Search " 

                    />
                </div>
            </form>
        );
    };

    return (
        <div id='ais-InstantSearch min-h-screen'>
            <InstantSearch
                indexName={ALGOLIA_INDEX_NAME}
                searchClient={searchClient}
            >   
                <Header>
                    <div className='hidden sm:block'>
                        <CustomSearchBox />
                    </div>
                </Header>
                <HeroSection/>

                <div className='sm:hidden'>
                    <CustomSearchBox />
                </div>
                <div className='lg:px-32'>
                    <div>
                        {/*<Filters/>*/}
                    </div>

                    <FilterContainer ShowRefinement={ShowRefinement}> 
                        <CustomHitsContainer className='hits ais-Hits-list' />
                    </FilterContainer>
                </div>
            </InstantSearch>
            <Footer/>
        </div>
    );
};

export default Listings;
