import React, { useState } from 'react';
import Logo from './core/header/Logo';
import { API } from './config';
import EmailValidator from 'email-validator';

/*
 * TODO:
 *      - Conditionally render this component on the listings page 
 *     
 */

const LeadGen = ({ setShowLeadGen }) => {
    const [email, setEmail] = useState('');

    const [status, setStatus] = useState({ loading: false, submitted: false, error: '' });

    const handleChange = e => setEmail(e.target.value);

    const handleSubmit = e => { 
        setStatus({ ...status, error: '' });
        e.preventDefault();
        if (email === 'letmeinliam') { //TEMP
            setShowLeadGen(false);
            return;
        }


        setStatus({...status, loading: true });
        if (!EmailValidator.validate(email)) {
            setStatus({ ...status, error: 'Please enter a valid email' });
            return;
        }

        fetch(`${API}/lead-gen`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        })
            .then(response => {
                setStatus({ loading: false, submitted: true });
            });
    };

     
    const Header = () => (
        <div className='flex h-16 justify-between border border-b'>
            <div className='flex'>
                <div className='ml-5 mr-2 flex items-center'>
                    <Logo classNames={'h-8 w-auto mt-2 shrink-0'} />
                </div>
            </div>
        </div>
    );


    return (
        <>
        <Header/>
        <div className="bg-white py-16 sm:py-12">
            
            <div className="relative sm:py-16">
                <div aria-hidden="true" className="hidden sm:block">
                    <div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
                    <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                        <defs>
                            <pattern
                                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                    </svg>
                </div>
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="relative overflow-hidden rounded-2xl bg-indigo-600 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
                        <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                            <svg
                                className="absolute inset-0 h-full w-full"
                                preserveAspectRatio="xMidYMid slice"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 1463 360"
                            >
                                <path
                                    className="text-accent-color text-opacity-40"
                                    fill="currentColor"
                                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                />
                                <path
                                    className="text-indigo-700 text-opacity-40"
                                    fill="currentColor"
                                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                />
                            </svg>
                        </div>
                        <div className="relative">
                            <div className="sm:text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                    Get notified when we&rsquo;re launching.
                                </h2>
                                <p className="mx-auto mt-6 max-w-2xl text-lg text-indigo-200 flex-0">
                                    Soon you'll be able to buy, sell, and trade sneakers and clothes! For now you can enter your email so we can notify you when we launch!
                                </p>
                            </div>

                            <form className="mt-12 sm:mx-auto sm:flex sm:max-w-lg">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="cta-email" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        id="cta-email"
                                        type="email"
                                        value={email}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                        placeholder="Enter your email"
                                    />
                                   { status.error && (<p className='text-md text-red-400'>{ status.error }</p>) }
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-3">
                                    { !status.submitted
                                        ?
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className={`block w-full rounded-md border border-transparent bg-indigo-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10 ${status.loading && 'animate-bounce'}`}
                                    >
                                        Notify Me
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className={`block w-full rounded-md border border-transparent bg-indigo-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-200 sm:px-10 animate-bounce`}
                                    >
                                        Done! See Ya soon!

                                    </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default LeadGen;
