import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../auth';
import { Link, useParams } from 'react-router-dom';
import { getTrade } from '../../user/apiUser';

const TradeMessages = () => {
  const [trade, setTrade] = useState({});
  const [loading, setLoading] = useState(true);
  const { urlTradeId } = useParams();
  const {user: {_id, name }, token } = isAuthenticated();

  useEffect(() => {
    getTrade(urlTradeId, _id, token).then(tradeData => {
      console.log(tradeData);
      setTrade(tradeData);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <>Loading</>
  }
  return (
    <> {trade.messages.map(message => {
      return (
      <p>{message.userName}: {message.message}</p>
      );  
    })}
    </> 
  );
};

export default TradeMessages;
