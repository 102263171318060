import { API } from '../../config';

export const createMessage = (userId, token, targetUserId, messages, productId) => {
    return fetch(`${API}/create-message/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            receiverId: targetUserId,
            senderId: userId,
            messages,
            productId
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const readMessage = (userId, token, messageId) => {
    return fetch(`${API}/message/${userId}/${messageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const checkIfAlreadyMessaged = (userId, productId) => {
     return fetch(`${API}/check-if-already-messaged`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
         body: JSON.stringify({ userId, productId })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));   
};

export const readMyMessages = (userId, token) => {
     return fetch(`${API}/read-my-messages/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));   
};
