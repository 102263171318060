import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../auth';
import { Link, useParams } from 'react-router-dom';
import { fetchWhatUsersWant, getAllItems } from '../apiAdmin';
import Layout from '../../core/Layout';

const WhatUsersWant = () => {
  const [items, setItems] = useState([]);
  const {user: { _id }, token } = isAuthenticated();

  useEffect(() => {

    fetchWhatUsersWant(_id, token).then(res => setItems(res));
  }, []);

  return (
    <Layout>
        {items.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {items.map((item) => (
              <Link
                to={`/loot/${item._id}`}
                target="_blank"
              >
                <li key={item._id} className="px-4 py-4 sm:px-6">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-indigo-600">
                        Item Posted: {item.name}
                      </p>
                      <img
                        className='h-32 w-32 object-cover'
                        src={item.primary_photo}
                        alt="wassup"
                      />
                      <p>{item.type}</p>
                    </div>
                    <div>
                      Item(s) they want:
                      {item.wantedStockxItems.map(item => (
                        <p>{item.stockxId.name} Size: {item.size}</p>
                      ))}
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        ) : (
          <p>loading....</p>
        )}
    </Layout>
  );

};

export default WhatUsersWant;
