import React from 'react';
import { io } from 'socket.io-client';
import { saveNotification } from './apiCore';
import { isAuthenticated } from '../auth';
import { SOCKET_API } from '../config';
const { v1: uuidv1 } = require('uuid');

//I know the parameters are super long and messy, Ill fix it later dont worry!
export const sendNotification = (type, tradeId, title, description, image, link, userId, targetId, isReceiver=false) => { 
    const URL = `${SOCKET_API}/notifications`;

    const notificationSocket = io(URL, { 
        transports: ['websocket'],
        autoConnect: false,
    },
    );
    const { user:{_id}, token } = isAuthenticated(); 

    notificationSocket.auth = { userId, targetId };
    notificationSocket.connect();
    const content = {type: type, tradeId: tradeId, title: title, description: description, image: image, link: link, uId: uuidv1(), isReceiver: isReceiver, dateAdded: Date()};
    notificationSocket.emit('send notification', {
        content,
        to: targetId
    }
    );
    
};
