import React, { lazy } from 'react';
import Listings from './core/Listings';
import ProductPage from './core/product_page/ProductPage';
import AdminRoute from './auth/AdminRoute';
import PrivateRoute from './auth/PrivateRoute';
import LeadGen from './LeadGen';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProductShare from './product_share/ProductShare';
import FetchEbayMessages from './admin/admin_dashboard/FetchEbayMessages';
import SearchSupportMessage from './admin/admin_dashboard/SearchSupportMessage';
import AllUserItems from './admin/admin_dashboard/AllUserItems';
import WhatUsersWant from './admin/admin_dashboard/WhatUsersWant';
import EbayTrades from './admin/admin_dashboard/EbayTrades';
import AllTrades from './admin/admin_dashboard/AllTrades';
import AllMessages from './admin/admin_dashboard/AllMessages';
import TradeMessages from './admin/admin_dashboard/TradeMessages';
import UserSearches from './admin/admin_dashboard/UserSearches';
import UserOnboarding from './admin/admin_dashboard/UserOnboarding';
import UserItemData from './admin/admin_dashboard/UserItemData';

const ProfileDashboard = lazy(() => import('./user/user_dashboard/ProfileDashboard'));
const ArchiveDashboard = lazy(() => import('./user/user_dashboard/ArchiveDashboard'));
const AddressDashboard = lazy(() => import('./user/user_dashboard/AddressDashboard'));
const ProductsDashboard = lazy(() => import('./user/user_dashboard/ProductsDashboard'));
const WalletDashboard = lazy(() => import('./user/user_dashboard/WalletDashboard'));
const LinkStripeMobile = lazy(() => import('./user/user_dashboard/LinkStripeMobile'));
const LinkPaypal = lazy(() => import('./user/user_dashboard/LinkPaypal'));
const LikedLoot = lazy(() => import('./user/user_dashboard/LikedLoot'));

const Trades = lazy(() => import('./user/trades/Trades'));
const Trade = lazy(() => import('./user/trades/Trade'));

const Message = lazy(() => import('./user/messages/Message'));

const Orders = lazy(() => import('./user/orders/Orders'));
const OrderFormFinished = lazy(() => import('./user/orders/FillOrder'));
const GenerateShippingCheckout = lazy(() => import('./user/orders/trade_checkout/GenerateShippingCheckout'));
const ConfirmOrder = lazy(() => import('./user/orders/ConfirmOrder'));
const Order = lazy(() => import('./user/orders/Order'));
const ShippingLabel = lazy(() => import('./user/orders/ShippingLabel'));

const AddProduct = lazy(() => import('./user/add_product/AddProduct'));
const EditProduct = lazy(() => import('./user/add_product/EditProduct'));

//const ProductPage = lazy(() => import('./core/product_page/ProductPage')); //no longer lazy loading product page
const RequestPasswordReset = lazy(() => import('./core/RequestPasswordReset'));
const ResetPassword = lazy(() => import('./core/ResetPassword'));
const VerifyEmail = lazy(() => import('./VerifyEmail'));
const SavePaypal = lazy(() => import('./user/SavePaypal'));
const GuestCheckout = lazy(() => import('./core/product_page/GuestCheckout'));
const Checkout = lazy(() => import('./core/product_page/Checkout'));
const MobileCheckout = lazy(() => import('./core/product_page/MobileCheckout'));
const MobileLinkPaypal = lazy(() => import('./user/add_product/MobileLinkPaypal'));
const RateUser = lazy(() => import('./core/user_rating/RateUser'));
const PaypalSellerPayShipping = lazy(() => import('./user/orders/paypal_orders/PaypalSellerPayShipping'));
const PaypalOrder = lazy(() => import('./user/orders/paypal_orders/PaypalOrder'));
const Page404 = lazy(() => import('./Page404'));
const TOS = lazy(() => import('./text_documents/TOS'));
const Contact = lazy(() => import('./core/Contact'));

const Privacy = lazy(() => import('./text_documents/Privacy'));
const FAQ = lazy(() => import('./text_documents/FAQ'));
const Verify = lazy(() => import('./verifier_pages/Verify'));
const VirtuallyVerify = lazy(() => import('./verifier_pages/VirtuallyVerify'));
const FixEbaySizes = lazy(() => import('./verifier_pages/FixEbaySizes'));
const BanUser = lazy(() => import('./verifier_pages/banUser'));
const NotificationDashboard = lazy(() => import('./notif_dashboard/NotificationDashboard'));
const CreateAffiliate = lazy(() => import('./affiliate/CreateAffiliate'));
const UserProfile = lazy(() => import('./user/user_profile/UserProfile'));
const AdminDashboard = lazy (() => import('./admin/admin_dashboard/AdminDashboard'));
const EbayMessages = lazy (() => import('./admin/admin_dashboard/EbayMessages'));
const EbayUserChatScreen = lazy (() => import('./admin/admin_dashboard/EbayUserChatScreen'));
const ReplaceShippingLabel = lazy (() => import('./admin/admin_dashboard/ReplaceShippingLabel'));



const Routes = () => { //NOTE: implement signin redirecting to dashboard/products page if has jwt token
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/notifyme' exact component={LeadGen}/>


                <Route path='/password-reset-request' exact component={RequestPasswordReset}/>
                <Route path='/reset-password' exact component={ResetPassword}/>
                <Route path='/verify-email' exact component={VerifyEmail}/>

                <Route path='/user-profile/:userId' exact component={UserProfile}/>

                <Route path='/loot/:productId' exact component={ProductPage}/>
                <Route path='/guest-checkout/:productId' exact component={GuestCheckout}/>
                <PrivateRoute path='/checkout/:productId' exact component={Checkout}/>
                <Route path='/mobile-checkout' exact component={MobileCheckout} />
                <Route path='/mobile-link-paypal' exact component={MobileLinkPaypal} />

                <PrivateRoute path='/dashboard/profile' exact component={ProfileDashboard}/>
                <PrivateRoute path='/dashboard/address' exact component={AddressDashboard}/>
                <PrivateRoute path='/dashboard/archive' exact component={ArchiveDashboard}/>
                <PrivateRoute path='/dashboard/products' exact component={ProductsDashboard}/>
                <PrivateRoute path='/dashboard/wallet' exact component={WalletDashboard}/>
                <Route path='/link-stripe-mobile' exact component={LinkStripeMobile}/>
                <PrivateRoute path='/dashboard/link-paypal' exact component={LinkPaypal}/>
                <PrivateRoute path='/dashboard/liked-loot' exact component={LikedLoot}/>

                <PrivateRoute path='/trades' exact component={Trades} />
                <PrivateRoute path='/add-loot' exact component={AddProduct}/> 
                <PrivateRoute path='/paypal-onboarding' exact component={SavePaypal}/>
                <PrivateRoute path='/edit/:productId/:userId' exact component={EditProduct}/>
                <PrivateRoute path='/trade/:urlTradeId' exact component={Trade}/>


                <PrivateRoute path='/orders' exact component={Orders}/>
                <PrivateRoute path='/order/shipping-label/:orderId' exact component={ShippingLabel}/>
                <PrivateRoute path='/order-form-finished' exact component={OrderFormFinished}/>
                <PrivateRoute path='/order/generate-shipping/:orderId' exact component={GenerateShippingCheckout}/>
                <PrivateRoute path='/confirm-order/:orderId' exact component={ConfirmOrder}/>
                <PrivateRoute path='/order/order-status/:orderId' exact component={Order}/> 

                <PrivateRoute path='/order/seller-pay-shipping/:paypalOrderId' exact component={PaypalSellerPayShipping}/>
                {/*<PrivateRoute path='/order/seller-shipping-label/:paypalOrderId' exact component={PaypalSellerShippingLabel}/>*/}
                <PrivateRoute path='/order/seller-order/:paypalOrderId' exact component={PaypalOrder}/>

                <PrivateRoute path='/rate-user/:orderId' exact component={RateUser}/>
                {/*                <Route path='/feedback' exact component={FeedbackPage}/>*/}

                
                <AdminRoute path='/verify' exact component={Verify} />
                <AdminRoute path='/virtually-verify' exact component={VirtuallyVerify} />
                <AdminRoute path='/fix-ebay-sizes' exact component={FixEbaySizes} />
                <AdminRoute path='/ban-user' exact component={BanUser} />
                <AdminRoute path='/notif-dashboard' exact component={NotificationDashboard} />

                <Route path='/product-share' exact component={ProductShare} />
                <AdminRoute path='/create-affiliate' exact component={CreateAffiliate} />

                <AdminRoute path='/admin-dashboard' exact component={AdminDashboard}/>
                <AdminRoute path='/ebay-user-messages/:userId' exact component={EbayMessages}/>
                <AdminRoute path='/ebay-user-chat/:userId/:messageId' exact component={EbayUserChatScreen}/>
                <AdminRoute path='/replace-shipping-label' exact component={ReplaceShippingLabel}/>
                <AdminRoute path='/ebay-messages' exact component={FetchEbayMessages}/>
                <AdminRoute path='/search-support-message' exact component={SearchSupportMessage}/>
                <AdminRoute path='/ebay-trades' exact component={EbayTrades}/>

                <AdminRoute path='/trade-messages/:urlTradeId' exact component={TradeMessages}/>
                <AdminRoute path='/searches' exact component={UserSearches}/>
                <AdminRoute path='/user-onboarding' exact component={UserOnboarding}/>

                <AdminRoute path='/all-trades' exact component={AllTrades}/>
                <AdminRoute path='/all-messages' exact component={AllMessages}/>
                <AdminRoute path='/all-user-items' exact component={AllUserItems}/>
                <AdminRoute path='/what-users-want' exact component={WhatUsersWant}/>
                <AdminRoute path='/user-item-data' exact component={UserItemData}/>

                <PrivateRoute path='/chat/:messageId' exact component={Message}/>
                  
                <Route path='/tos' exact component={TOS}/>
                <Route path='/privacy' exact component={Privacy}/>
                <Route path='/contact' exact component={Contact}/>
                <Route path='/faq' exact component={FAQ}/>
                <Route path='/' component={() => {
                  window.location.href = 'https://download.lootswap.com/';
                    return null;
                }}/>

                <Route path='*' component={Page404}/>

                
                
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
