import { API, GOOGLE_API } from '../../config';
var shippo = require('shippo')('shippo_test_78a3a7e8883b86a4f9d562017f313dfd9b24fb71');

export const generateSignin = (email, redirectUrl, _id) => {
    return fetch(`${API}/generate-signin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({email: email, redirectUrl: redirectUrl, _id: _id})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//TODO: components array is inconsistent, use the types to sort the array and input data that way 
export const estimateShipping = async (addrDetails, parcel, addressFrom, setShippingCost) => { 

    const formattedAddr = addrDetails.results[0].formatted_address;
    const splitAddr = await formattedAddr.split(',');  //probably an unreliable way to get addr details
    const stateZip = await splitAddr[2].split(' ');


    const addressTo = await {
        'name': 'John Doe',
        'street1': splitAddr[0],
        'city': splitAddr[1],
        'state': stateZip[1],
        'zip': stateZip[2],
        'country': 'US',
    };


    shippo.shipment.create({
        'address_from': addressFrom,
        'address_to': addressTo,
        'parcels': [parcel],
        'async': false
    }, async (err, shipment) => {
        if (shipment.rates.length !== 0) {
            shipment.rates.forEach(rate => {
                if (rate.attributes.includes('CHEAPEST')) {
                    setShippingCost('$'+rate.amount);
                }
            });
        }
        else if (err) {
            setShippingCost('Unable to load shipping rate');
        }
        else {
            setShippingCost('Unable to load shipping rate');
        }
    });
};

export const validateAddress= (addrTo) => {
    shippo.address.create(addrTo, async (err, address) => {
        if (err) {
            return {error: err}
        }
        const { validation_results } = await address;
        if (!validation_results.is_valid) {
            return {error: validation_results.messages[0].text}
        }
        else {
            return {success: 'Address successfully validated'}
        }
    });
};

export const deleteProduct = (productId, userId, token) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const likeProduct = (productId, userId, token) => {
    return fetch(`${API}/user/like-product/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ productId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
export const unlikeProduct = (productId, userId, token) => {
    return fetch(`${API}/user/unlike-product/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ productId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
