import { io } from 'socket.io-client';
import { SOCKET_API } from '../config';

const URL = `${SOCKET_API}/notifications`;
const notificationSocket = io(URL, { 
    autoConnect: false,
    transports: ['websocket'],
},

);

export default notificationSocket;


