import React, { useEffect, useState } from 'react';
import { fetchAllMessages } from '../apiAdmin';
import { isAuthenticated } from '../../auth';
import {Link} from 'react-router-dom';
import Layout from '../../core/Layout';
// TODO: view message chat
const AllMessages = () => {
  const [messages, setMessages] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    fetchAllMessages(_id, token)
      .then(data => {
        if (data.error) {
          console.log(data.error);
        } else {
          setMessages(data.messages);
        }
      })
      .catch(err => console.error("Error fetching eBay messages:", err));

  }, []);

  const renderDate = (createdAt) => {
    var date = new Date(createdAt);
    return <p>{((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()}</p>
  };

  return (
    <Layout>  
      <h3>Note*</h3><h4>This list does NOT include eBay chats</h4>
      <div>
        {messages.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {messages.map((message) => (
              <Link
                to={`/chat/${message._id}`}
              >
                <li key={message._id} className="px-4 py-4 sm:px-6">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-indigo-600">
                        Sender: {message.sender.name} {message.sender.isFromEbay && (<p className='text-blue-600'>EBAY ACCOUNT</p>)}

                      </p>
                      <p className="text-sm font-medium text-green-600">
                        Receiver: {message.receiver.name} {message.receiver.isFromEbay && (<p className='text-blue-600'>EBAY ACCOUNT</p>)}
                      </p>
                    </div>
                    <div>
                      {renderDate(message.createdAt)}
                    </div>
                    <div>
                      <p>{message?.product?.name}</p>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        ) : (
          <p>Loading....</p>
        )}
      </div>
    </Layout>
  );

};

export default AllMessages;
