import React from 'react';

const MoneyOffer = ({senderMoneyOffer, setSenderMoneyOffer}) => {

    const handleChange = (e) => {
        const value = e.target.value;
        setSenderMoneyOffer(value);
    };

    //input validation
    return (
        <div className='md:mx-32 flex justify-center items-center'>
            <label htmlFor="price" className="block text-sm font-sm text-gray-700 mr-2">
                Money offer: 
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                    type="number"
                    min='0.00'
                    max='100000.00'
                    step='0.01'
                    name="price"
                    id="price"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    value={senderMoneyOffer}
                    onChange={handleChange}
                    
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                        USD
                    </span>
                </div>
            </div>
        </div>

    );
};

export default MoneyOffer;
