import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import '@themesberg/flowbite';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import Loading from './core/Loading';

import { FirebaseProvider } from './FirebaseContext';

Sentry.init({
     dsn: "https://7b0bc98acfb24203a1f28947e6f3eeb1@o1429408.ingest.sentry.io/6780045",
     integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0, 
});

ReactDOM.render(
    <Suspense fallback={<Loading/>}>
    <FirebaseProvider>
        <React.StrictMode>
            <Routes />
        </React.StrictMode>
    </FirebaseProvider>
    </Suspense>,
  document.getElementById('root')
);

