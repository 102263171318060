import React, { useState, useRef, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { signout, isAuthenticated } from '../../auth';
import notificationSocket from '../notification_socket';
import { getUserInfo } from '../../user/apiUser';
import { deleteNotification } from '../apiCore';
import SignupModal from '../../user/SignupModal';
import SigninModal from '../../user/SigninModal';

import LoggedInHeader from './LoggedInHeader';
import Logo from './Logo';

//tailwind
import { Popover, Transition, Disclosure, Menu } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    XMarkIcon,
    ChatIcon,
    InboxIcon,
    CheckCircleIcon,
    BanIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

const Header = ({ history, children }) => {
    const [openSignup, setOpenSignup] = useState(false); //signup modal
    const handleOpenSignup = () => setOpenSignup(true);
    const handleCloseSignup = () => setOpenSignup(false);

    const [openSignin, setOpenSignin] = useState(false);
    const handleOpenSignin = () => setOpenSignin(true);
    const handleCloseSignin = () => setOpenSignin(false);


    return (
        <>{ isAuthenticated() ? (<LoggedInHeader history={history}>{children}</LoggedInHeader>) : (

            <div className='min-h-full sticky top-0 z-50'>
                <Popover className="relative bg-white">
                    <SignupModal open={openSignup} handleClose={handleCloseSignup} handleOpenSignup={handleOpenSignup}/>
                    <SigninModal open={openSignin} handleClose={handleCloseSignin} handleOpenSignup={handleOpenSignup}/>
                    <div className="mx-auto px-4 sm:px-6">
                        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                            <div className="justify-start">
                                <Logo classNames={'h-8 w-auto shrink-0 mt-2'}/>
                            </div>
                            <div className='lg:w-1/2'>
                                    {children}
                            </div>


                            <div className="mr-2 -my-2 md:hidden">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open menu</span>
                                    <Bars3Icon className="h-6 w-6 pl-2" aria-hidden="true" />
                                </Popover.Button>
                            </div>


                            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                <a onClick={handleOpenSignin} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                                    Sign in
                                </a>
                                <a
                                    onClick={handleOpenSignup}
                                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    Sign up
                                </a>
                            </div>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Logo classNames={'h-8'}/>
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-6 px-5 space-y-6">
                                    <div>
                                        <a
                                            onClick={handleOpenSignup}
                                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                        >
                                            Sign up
                                        </a>
                                        <p className="mt-6 text-center text-base font-medium text-gray-500">
                                            <a onClick={handleOpenSignin} className="text-indigo-600 hover:text-indigo-500">
                                                Sign in
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        )}</>
    );
};

export default withRouter(Header);
