import { API } from '../config';

/*
* ebay user list
*/

export const getEbayUsers = (page, token) => {
    return fetch(`${API}/all-ebay-users?page=${page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getEbayUserMessages= (userId, token) => {
    return fetch(`${API}/read-my-messages/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSingleMessageChat = (userId, messageId, token) => {
    return fetch(`${API}/message/${userId}/${messageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sendMessage = async (messageData, token) => {
    return fetch(`${API}/message/${messageData.userId}/${messageData.messageId}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(messageData)
    })
    .then(response => {console.log('response', response)})
    .catch(err => console.log(err));
};

export const fetchEbayMessages= (userId, token) => {
    return fetch(`${API}/ebay-messages/${userId}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const searchSupportMessage = (userId, token, query) => {
    return fetch(`${API}/search-support-messages/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userName: query})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchSupportMessages = (userId, token) => {
    return fetch(`${API}/fetch-support-messages/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchEbayTrades = (userId, token) => {
    return fetch(`${API}/fetch-ebay-trades/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchAllTrades = (userId, token) => {
    return fetch(`${API}/all-trades/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllItems = (userId, token) => {
    return fetch(`${API}/all-user-products/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const searchesWithSize = (userId, token) => {
    return fetch(`${API}/searches-with-size/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const userOnboardingData = (userId, token) => {
    return fetch(`${API}/onboarding-data/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchAllMessages = (userId, token) => {
    return fetch(`${API}/all-messages/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchWhatUsersWant = (userId, token) => {
    return fetch(`${API}/what-users-want/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchUserItemData = (userId, token, size, brand, type) => {
    return fetch(`${API}/user-item-data/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({size, brand, type})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const fetchAbandonedCheckouts = () => {
    return fetch(`${API}/abandoned-checkouts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
