import logo from '../../images/lootswap_logo2.png';

/* className="sm:h-1 sm:w-auto md:h-8 md:w-auto lg:h-10 lg:w-auto "*/
const Logo = ({ classNames }) => (
    <a href='/'>
        <img
            className={`${classNames}`}
            src={logo}
            alt="lootswap"
        />
    </a>
);

export default Logo;
