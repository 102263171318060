import React, { useState } from 'react';
import { API } from '../../config';

const ChooseItemCard = ({item}) => {
    const [isSelected, setIsSelected] = useState(false);
    const handleToggle = () => {isSelected ? setIsSelected(false) : setIsSelected(true);}

    return (
        <div className="relative" onClick={handleToggle}>
        <div className={`group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 ${isSelected ? 'border-4 border-indigo-500' : '' }`}>
            <img src={item.primary_photo} alt={item.name} className="object-cover pointer-events-none group-hover:opacity-75 rounded-lg" />
            <button type="button" className="absolute inset-0 focus:outline-none">
              <span className="sr-only">View details for {item.name}</span>
            </button>
          </div>
          <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{item.name}</p>
        </div>
    );

};

export default ChooseItemCard;
