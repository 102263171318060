import React, { useState } from 'react';
import { isAuthenticated } from '../../auth';
import { virtuallyVerify, virtuallyDecline } from '../../verifier_pages/apiVerify'
import DeleteButton from './DeleteButton';
import MessageButton from './MessageButton';
import { useHistory, useLocation } from 'react-router-dom';

const ButtonOptions = ({ item, isTrading, productId, TradeButton, BuyButton, setSignupOpen}) => {
    const [verifiedStatus, setVerifiedStatus] = useState('Verify Item');
    const [notifyAll, setNotifyAll] = useState(false);
    const [declineStatus, setDeclineStatus] = useState('Decline Item');
    const [customDeclineStatus, setCustomDeclineStatus] = useState('Custom Decline Message');

    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page') || 0;

    const [customMessage, setCustomMessage] = useState('');

    const history = useHistory();

    if (true) {
        if (isAuthenticated() && isAuthenticated().user.role === 1) {
            const { user: { _id }, token } = isAuthenticated();
            const handleVerify = () => {
                virtuallyVerify(_id, token, item.userId, productId, notifyAll).then(res => {
                    if (res.error) {
                        setVerifiedStatus(res.error);
                    }
                    else {
                        history.push(`/virtually-verify?page=${page}`); 
                        setVerifiedStatus('Success!')
                    }
                });
            };

            const handleDecline = (message) => {
                if (message === '') {
                    setCustomDeclineStatus('Custom message is blank');
                }
                else {
                    virtuallyDecline(_id, token, item.userId, message, productId).then(res => {
                        if (res.error) {
                            setDeclineStatus(res.error);
                        }
                        else {
                            setDeclineStatus('Success!')
                        }
                    });
                }
            };

            return (
                <>
                    <button
                        type="button"
                        className={`bg-green-600 max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full mt-3`}
                        onClick={handleVerify}
                    >
                        {verifiedStatus}
                    </button>
                  <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        onChange={() => setNotifyAll(!notifyAll)}
                        value={notifyAll}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                  <div className="text-sm leading-6">
                    <p className="text-gray-500 pl-2 py-5">Notify all users about this new item</p>
                  </div>
                    </div>


                    <button
                        type="button"
                        onClick={() => handleDecline('After close inspection of your photos, we have decided not to accept your item on lootswap. If you think this is a mistkae, please contact our support at support@lootswap.com')}
                        className={`bg-red-600 max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-white font-medium hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full mt-3`}
                    >
                        {declineStatus}
                    </button>

                    <button
                        type="button"
                        onClick={() => handleDecline(customMessage)}
                        className={`bg-red-600 max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-white font-medium hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full mt-3`}

                    >
                        {customDeclineStatus}
                    </button>
                                <textarea
                                    placeholder='make sure you tell them to edit their item via profile dashboard'
                                    id="description"
                                    onChange={e => setCustomMessage(e.target.value)}
                                    value={customMessage}
                                    name="description"
                                    rows={2}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md mt-3"
                                    defaultValue={''}
                                />
                    
                </>
            );
        }
        else {
            return (
                <button
                    type="button"
                    disabled
                    className={`cursor-not-allowed bg-white max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-black hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full mt-3`}
                >
                    This Item is Awaiting Verification
                </button>
            );
        }
    }


    if (!item.isVisible) {
        return (
            <button
                type="button"
                disabled
                className={`cursor-not-allowed bg-white max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-black hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full mt-3`}
            >
                Item no longer avaliable
            </button>
        );
    }


    if (isAuthenticated() && isAuthenticated().user._id === item.userId) {
        const { user: { _id }, token } = isAuthenticated();
        return (
            <>
                <a href={`/edit/${productId}/${_id}`}>
                    <button
                        type="button"
                        className={`bg-white max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-black hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full`}
                    >
                        Edit Listing
                    </button>
                </a>

                <DeleteButton productId={productId} userId={_id} token={token}/>
            </>

        );

    }
    else if (isTrading) {
        return (
            <button
                type="button"
                disabled
                className={`cursor-not-allowed bg-white max-w-xs flex-1 border rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-black hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full`}
            >
                Already Trading
            </button>
        );
    }
    else if (item.type === 'trade-sell') {
        return (
            <div className="mt-2">
                <BuyButton disabled={false} item={item}/>
                <TradeButton disabled={false}/>
                <MessageButton product={item} setSignupOpen={setSignupOpen}/>
            </div>
        );
    }
    else if (item.type === 'trade-only') {
        return (
            <div className='mt-2'>
                <BuyButton disabled={true} item={item}/>
                <TradeButton diabled={false} />
                <MessageButton product={item} setSignupOpen={setSignupOpen}/>
            </div> 
        );

    }
    else if (item.type === 'sell-only') {
        return (
            <div className=''>
                <BuyButton disabled={false} item={item}/>
                <TradeButton disabled={true}/>
                <MessageButton product={item} setSignupOpen={setSignupOpen}/>
            </div> 
        );

    }
    else {
        return (
            <></>
        );
    }
};

export default ButtonOptions;
