import React, { useState, useEffect } from 'react';
import Layout from '../../core/Layout';
import { isAuthenticated } from '../../auth';
import {Link} from 'react-router-dom';
import { searchSupportMessage, fetchSupportMessages } from '../apiAdmin';

const SearchSupportMessage = () => {
  const { token, user: {_id} } = isAuthenticated();

  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchSupportMessages(_id, token).then(res => setMessages(res.messages));
  }, [])

  const handleSubmit = () => {
    searchSupportMessage(_id, token, query).then(res => {
      if (res.error) {
        setError(res.error);
        return;
      }
      console.log(res);
      setMessages(res.messages);
    });
  };


  return (
    <div>
        <input
            type="text"
            onChange={e => setQuery(e.target.value)}
            value={query}
            name="user name"
            id="user name"
            placeholder={'Search for EXACT username here'}
            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
        />
      <button
        onClick={handleSubmit}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
          Submit
      </button>

      <p>{query}</p>
      {messages.length > 0 ? (
        <ul className="divide-y divide-gray-200">
          {error && <p className='text-red-600'>{error}</p>}
          {messages.map((message) => (

            <li key={message._id} className="px-4 py-4 sm:px-6">
              <Link
                to={`/ebay-user-chat/${message.sender._id}/${message._id}`}
              >
                <div className="flex justify-between">
                  <div>
                    <p className="text-sm font-medium text-indigo-600">
                      Sender: {message?.sender?.name}
                    </p>
                    <p className="text-sm font-medium text-green-600">
                      Receiver: {message?.receiver?.name}
                    </p>
                  </div>
                  <div>
                    {message.senderNewMessage && (<p className='text-red-600'>NEW MESSAGE!</p>)}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>Search search by username</p>
      )}
    </div>
  );
};

export default SearchSupportMessage;
