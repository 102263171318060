import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { likeProduct, unlikeProduct } from './apiProduct';
import { isAuthenticated } from '../../auth';

//TODO:
//   - integrate with Algolia Recommend
//   - show signin if not signed in

const LikeProduct = ({ liked, setLiked, productId, handleOpenSignup }) => {
    const handleLike = () => {
        if (isAuthenticated()) {
            const { user: { _id }, token } = isAuthenticated();
            setLiked(true);
            likeProduct(productId, _id, token);
        }
    };

    const handleUnlike = () => {
        if (isAuthenticated()) {
            setLiked(false);
            const { user: { _id }, token } = isAuthenticated();
            unlikeProduct(productId, _id, token);
        }
    };

    if (isAuthenticated()) {
        return (
            <>
                {liked ? 
                    <HeartIconSolid 
                        className='h-6 w-6 cursor-pointer'
                        onClick={handleUnlike}
                    />
                :
                    <HeartIcon 
                        className='h-6 w-6 cursor-pointer'
                        onClick={handleLike}
                    />
                }
            </>
        );
    }
    else {
        return (
            <HeartIcon 
                className='h-6 w-6 cursor-pointer'
                onClick={handleOpenSignup}
            />
        );
    }
};

export default LikeProduct;
