import React, { useEffect, useState } from 'react';
import { fetchEbayMessages } from '../apiAdmin';
import { isAuthenticated } from '../../auth';
import {Link} from 'react-router-dom';

const FetchEbayMessages = () => {
  const [messages, setMessages] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    fetchEbayMessages(_id, token)
      .then(data => {
        if (data.error) {
          console.log(data.error);
        } else {
          setMessages(data);
        }
      })
      .catch(err => console.error("Error fetching eBay messages:", err));
  }, [_id, token]);

  return (
    <div>
      {messages.length > 0 ? (
        <ul className="divide-y divide-gray-200">
          {messages.map((message) => (

            <li key={message._id} className="px-4 py-4 sm:px-6">
              <Link
                to={`/ebay-user-chat/${
                message.receiver?.isFromEbay ? message.receiver._id : message.sender._id
                }/${message._id}`}
              >
              <div className="flex justify-between">
                <div>
                  <p className="text-sm font-medium text-indigo-600">
                    Sender: {message.sender.name}
                  </p>
                  <p className="text-sm font-medium text-green-600">
                    Receiver: {message.receiver.name}
                  </p>
                </div>
                <div>
                  {message.receiver.isFromEbay ? (
                    <p className="text-sm text-red-500">
                      Receiver New Message: {message.receiverNewMessage ? "Yes" : "No"}
                    </p>
                  ) : (
                    <p className="text-sm text-blue-500">
                      Sender New Message: {message.senderNewMessage ? "Yes" : "No"}
                    </p>
                  )}
                </div>
              </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No eBay messages found.</p>
      )}
    </div>
  );
};

export default FetchEbayMessages;
