import React, { useContext, useState, useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCz3Cd7Q51Yh_0SFXR9LOu9HyFlxCyISA0",
  authDomain: "lootswap-231f7.firebaseapp.com",
  projectId: "lootswap-231f7",
  storageBucket: "lootswap-231f7.appspot.com",
  messagingSenderId: "243371662211",
  appId: "1:243371662211:web:84b2e1846812770b1cf4b6",
  measurementId: "G-SGZNS8X1FB"
};

const AnalyticsContext = React.createContext();

export const useAnalyticsContext = () => {
    return useContext(AnalyticsContext);
};

export const FirebaseProvider = ({ children }) => {
    // Initialize firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return (
        <AnalyticsContext.Provider value={analytics}>
            {children}
        </AnalyticsContext.Provider>
    );
};
