import React, { useEffect, useState } from 'react';
import Layout from '../../core/Layout';
import { isAuthenticated } from '../../auth';
import { userOnboardingData } from '../apiAdmin';
import { CSVLink } from 'react-csv';

const UserOnboarding = () => {
  const [onboardingData, setOnboardingData] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    userOnboardingData(_id, token).then(data => {
      setOnboardingData(data);
    });
  }, []);

  const headers = [
    { label: "User Name", key: "userName" },
    { label: "Shoe Sizes", key: "shoeSizes" },
    { label: "Tops Sizes", key: "topsSize" },
    { label: "Bottoms Sizes", key: "bottomsSize" },
    { label: "Favorite Brands", key: "favoriteBrands" },
    { label: "Condition Interest", key: "conditionInterest" },
  ];

  const csvReport = {
    filename: 'UserSearchesReport.csv',
    headers: headers,
    data: onboardingData.map(data => ({
      userName: data.name,
      shoeSizes: data?.onboardingData?.shoeSizes,
      topsSize: data?.onboardingData?.topsSize,
      bottomsSize: data?.onboardingData?.bottomsSize,
      favoriteBrands: data?.onboardingData?.favoriteBrands,
      conditionInterest: data?.onboardingData?.conditionInterest,
    })),
    delimiter: ","
  };

  return (
    <Layout>
      <div>
        <CSVLink {...csvReport} className="mb-3 border border-w-5 border-gray-600">Download CSV</CSVLink>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header.key} className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {onboardingData.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.name}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.shoeSizes}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.topsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.bottomsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.favoriteBrands}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{data?.onboardingData?.conditionInterest}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>

  );
};

export default UserOnboarding;
