export const upperClothingSize = [
  {label: 'XS', value: 'XS'},
  {label: 'S', value: 'S'},
  {label: 'M', value: 'M'},
  {label: 'L', value: 'L'},
  {label: 'XL', value: 'XL'},
  {label: 'XXL', value: 'XXL'},
];

export const lowerClothingSize = [
  {label: 'XS', value: 'XS'},
  {label: 'S', value: 'S'},
  {label: 'M', value: 'M'},
  {label: 'L', value: 'L'},
  {label: 'XL', value: 'XL'},
  {label: 'XXL', value: 'XXL'},
  {label: '26', value: '26'},
  {label: '27', value: '27'},
  {label: '28', value: '28'},
  {label: '29', value: '29'},
  {label: '30', value: '30'},
  {label: '31', value: '31'},
  {label: '32', value: '32'},
  {label: '33', value: '33'},
  {label: '34', value: '34'},
  {label: '35', value: '35'},
  {label: '36', value: '36'},
  {label: '37', value: '37'},
  {label: '38', value: '38'},
  {label: '39', value: '39'},
  {label: '40', value: '40'},
  {label: '41', value: '41'},
  {label: '42', value: '42'},
  {label: '43', value: '43'},
  {label: '44', value: '44'},
];

export const shoesSizeList = [
  {label: '3.5M (35)', value: '3.5'},
  {label: '4M (36)', value: '4'},
  {label: '4.5M (36)', value: '4.5'},
  {label: '5M (37)', value: '5'},
  {label: '5.5M (37)', value: '5.5'},
  {label: '6M (39)', value: '6'},
  {label: '6.5M (39)', value: '6.5'},
  {label: '7M (40)  ', value: '7'},
  {label: '7.5M (40-41)', value: '7.5'},
  {label: '8M (41)', value: '8'},
  {label: '8.5M (41-42)', value: '8.5'},
  {label: '9M (42)', value: '9'},
  {label: '9.5M (42-43)', value: '9.5'},
  {label: '10M (43)', value: '10'},
  {label: '10.5M (43-44)', value: '10.5'},
  {label: '11M (44)', value: '11'},
  {label: '11.5M (44-45)', value: '11.5'},
  {label: '12M (45)', value: '12'},
  {label: '12.5M (45-46)', value: '12.5'},
  {label: '13M (46)', value: '13'},
  {label: '13.5M (46-47)', value: '13.5'},
  {label: '14M (47)', value: '14'},
  {label: '14.5M (47-48)', value: '14.5'},
  {label: '15M (48)', value: '15'},

  {label: '5W (35-36)', value: '5W'},
  {label: '5.5W (36)', value: '5.5W'},
  {label: '6W (36-37)', value: '6W'},
  {label: '6.5W (37)', value: '6.5W'},
  {label: '7W (37-38)', value: '7W'},
  {label: '7.5W (38)', value: '7.5W'},
  {label: '8W (38-39)', value: '8W'},
  {label: '8.5W (39)', value: '8.5W'},
  {label: '9W (39-40)', value: '9W'},
  {label: '9.5W (40)', value: '9.5W'},
  {label: '10W (40-41)', value: '10W'},
  {label: '10.5W (41)', value: '10.5W'},
  {label: '11W (41-42)', value: '11W'},
  {label: '11.5W (42)', value: '11.5W'},
  {label: '12W (41-43)', value: '12W'},
  {label: '13W (43-44)', value: '13W'},

  //Youth
  {label: '1Y', value: '1Y'},
  {label: '1.5Y', value: '1.5Y'},
  {label: '2Y', value: '2Y'},
  {label: '2.5Y', value: '2.5Y'},
  {label: '3Y', value: '3Y'},
  {label: '3.5Y', value: '3.5Y'},
  {label: '4Y', value: '4Y'},
  {label: '4.5Y', value: '4.5Y'},
  {label: '5Y', value: '5Y'},
  {label: '5.5Y', value: '5.5Y'},
  {label: '6Y', value: '6Y'},
  {label: '6.5Y', value: '6.5Y'},
  {label: '7Y', value: '7Y'},

  {label: '1K', value: '1K'},
  {label: '2K', value: '2K'},
  {label: '3K', value: '3K'},
  {label: '4K', value: '4K'},
  {label: '5K', value: '5K'},
  {label: '5.5K', value: '5.5K'},
  {label: '6K', value: '6K'},
  {label: '6.5K', value: '6.5K'},
  {label: '7K', value: '7K'},
  {label: '7.5K', value: '7.5K'},
  {label: '8K', value: '8K'},
  {label: '8.5K', value: '8.5K'},
  {label: '9K', value: '9K'},
  {label: '9.5K', value: '9.5K'},
  {label: '10K', value: '10K'},
  {label: '10.5K', value: '10.5K'},
  {label: '11K', value: '11K'},
  {label: '11.5K', value: '11.5K'},
  {label: '12K', value: '12K'},
  {label: '12.5K', value: '12.5K'},
  {label: '13K', value: '13K'},
  {label: '13.5K', value: '13.5K'},
  {label: '1C (16)', value: '1C'},
  {label: '2C (17)', value: '2C'},
  {label: '3C (18.5)', value: '3C'},
  {label: '4C (19.5)', value: '4C'},
  {label: '5C (21)', value: '5C'},
  {label: '6C (22)', value: '6C'},
  {label: '7C (23.5)', value: '7C'},
  {label: '8C (25)', value: '8C'},
  {label: '9C (26)', value: '9C'},
  {label: '10C (27)', value: '10C'},
  {label: '10.5C (27.5)', value: '10.5C'},
  {label: '11C (28)', value: '11C'},
  {label: '11.5C (28.5)', value: '11.5C'},
  {label: '12C (29.5)', value: '12C'},
  {label: '12.5C (30)', value: '12.5C'},
  {label: '13C (31)', value: '13C'},
  {label: '13.5C (31.5)', value: '13.5C'},
];



export const brandsList = [
  {label: 'Vintage', value: 'Vintage'},
  {label: 'Japanese Brand', value: 'Japanese Brand'},
  {label: 'Streetwear', value: 'Streetwear'},
  {label: 'Nike', value: 'Nike'},
  {label: 'Supreme', value: 'Supreme'},
  {label: 'Adidas', value: 'Adidas'},
  {label: 'Band Tees', value: 'Band Tees'},
  {label: 'Designer', value: 'Designer'},
  {label: 'Street Fashion', value: 'Street Fashion'},
  {label: 'Polo Ralph Lauren', value: 'Polo Ralph Lauren'},
  {label: 'American Vintage', value: 'American Vintage'},
  {label: 'Made In Usa', value: 'Made In Usa'},
  {label: "Levi's", value: "Levi's"},
  {label: 'Jordan', value: 'Jordan'},
  {label: 'Champion', value: 'Champion'},
  {label: 'Burberry', value: 'Burberry'},
  {label: 'Bape', value: 'Bape'},
  {label: 'Movie', value: 'Movie'},
  {label: 'Rare', value: 'Rare'},
  {label: 'Uniqlo', value: 'Uniqlo'},
  {label: 'Custom', value: 'Custom'},
  {label: 'Jewelry', value: 'Jewelry'},
  {label: 'Tommy Hilfiger', value: 'Tommy Hilfiger'},
  {label: 'Cartoon Network', value: 'Cartoon Network'},
  {label: 'Disney', value: 'Disney'},
  {label: 'Sportswear', value: 'Sportswear'},
  {label: 'The North Face', value: 'The North Face'},
  {label: 'Luxury', value: 'Luxury'},
  {label: 'Carhartt', value: 'Carhartt'},
  {label: 'Stussy', value: 'Stussy'},
  {label: 'Anti Social Social Club', value: 'Anti Social Social Club'},
  {label: 'New Era(20141)', value: 'New Era(20141)'},
  {label: 'Other', value: 'Other'},
  {label: 'Art', value: 'Art'},
  {label: 'Unbranded', value: 'Unbranded'},
  {label: 'NFL', value: 'NFL'},
  {label: "Levi's Vintage Clothing", value: "Levi's Vintage Clothing"},
  {label: 'Gucci', value: 'Gucci'},
  {label: 'Distressed Denim', value: 'Distressed Denim'},
  {label: 'Issey Miyake', value: 'Issey Miyake'},
  {label: 'Rock T Shirt', value: 'Rock T Shirt'},
  {label: 'Harley Davidson', value: 'Harley Davidson'},
  {label: 'Yves Saint Laurent', value: 'Yves Saint Laurent'},
  {label: 'Comme des Garcons', value: 'Comme des Garcons'},
  {label: 'MLB', value: 'MLB'},
  {label: 'Racing', value: 'Racing'},
  {label: 'Military', value: 'Military'},
  {label: 'Ralph Lauren', value: 'Ralph Lauren'},
  {label: 'NBA', value: 'NBA'},
  {label: 'Reebok', value: 'Reebok'},
  {label: 'Valentino', value: 'Valentino'},
  {label: 'Italian Designers', value: 'Italian Designers'},
  {label: 'Very Rare', value: 'Very Rare'},
  {label: 'Prada', value: 'Prada'},
  {label: 'Rap Tees', value: 'Rap Tees'},
  {label: 'Undercover', value: 'Undercover'},
  {label: 'Palace', value: 'Palace'},
  {label: 'Vans', value: 'Vans'},
  {label: 'Hanes', value: 'Hanes'},
  {label: 'Anima', value: 'Anima'},
  {label: 'Fruit Of The Loom', value: 'Fruit Of The Loom'},
  {label: 'Yohji Yamamoto', value: 'Yohji Yamamoto'},
  {label: 'Fila', value: 'Fila'},
  {label: 'Saint Laurent Paris', value: 'Saint Laurent Paris'},
  {label: 'Dior', value: 'Dior'},
  {label: 'Number (N)ine', value: 'Number (N)ine'},
  {label: 'Guess', value: 'Guess'},
  {label: 'Hysteric Glamour', value: 'Hysteric Glamour'},
  {label: 'Puma', value: 'Puma'},
  {label: 'Mickey Mouse', value: 'Mickey Mouse'},
  {label: 'Travis Scott', value: 'Travis Scott'},
  {label: 'Brand', value: 'Brand'},
  {label: 'Hawaiian Shirt', value: 'Hawaiian Shirt'},
  {label: 'Necklace', value: 'Necklace'},
  {label: 'Converse', value: 'Converse'},
  {label: 'Dolce & Gabbana', value: 'Dolce & Gabbana'},
  {label: 'Versace', value: 'Versace'},
  {label: 'Gildan', value: 'Gildan'},
  {label: 'Kapital', value: 'Kapital'},
  {label: 'Off-White', value: 'Off-White'},
  {label: 'Lacoste', value: 'Lacoste'},
  {label: 'Hype', value: 'Hype'},
  {label: 'Varsity Jacket', value: 'Varsity Jacket'},
  {label: 'Custom Made', value: 'Custom Made'},
  {label: 'Acne Studios', value: 'Acne Studios'},
  {label: 'Soccer Jersey', value: 'Soccer Jersey'},
  {label: 'Maison Margiela', value: 'Maison Margiela'},
  {label: 'Outdoor Life', value: 'Outdoor Life'},
  {label: 'Gap', value: 'Gap'},
  {label: 'Japanese Traditional', value: 'Japanese Traditional'},
  {label: 'Louis Vuitton', value: 'Louis Vuitton'},
  {label: 'Hat', value: 'Hat'},
  {label: 'Columbia', value: 'Columbia'},
  {label: 'HarlemBling', value: 'HarlemBling'},
  {label: 'Patagonia', value: 'Patagonia'},
  {label: 'Balenciaga', value: 'Balenciaga'},
  {label: 'Calvin Klein', value: 'Calvin Klein'},
  {label: 'Rick Owens', value: 'Rick Owens'},
  {label: 'Flannel', value: 'Flannel'},
  {label: 'Kappa', value: 'Kappa'},
  {label: 'Unknown', value: 'Unknown'},
  {label: 'Fear of God', value: 'Fear of God'},
  {label: 'Chrome Hearts', value: 'Chrome Hearts'},
  {label: 'Denim Tears', value: 'Denim Tears'},
  {label: 'New Balance', value: 'New Balance '},
  {label: 'ASICS', value: 'ASICS'},
];

