import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { isAuthenticated } from '../../auth';
import ChooseItemCard from './ChooseItemCard';
import MoneyOffer from './MoneyOffer';
import { startTrade } from '../apiCore';
import { sendNotification } from '../sendNotification';

const ChooseItemModal = ({open, handleClose, items, listing}) => { //listing is the item the sender is about to trade with
    const cancelButtonRef = useRef(null);
    const history = useHistory();

    const [selectedItems, setSelectedItems] = useState([]);
    const [senderMoneyOffer, setSenderMoneyOffer] = useState('');

    const [error, setError] = useState('');
    const { user: {_id, name}, token } = isAuthenticated();

    const handleSelect = (itemId) => {
	if (selectedItems.includes(itemId)) {
	    let newArr = selectedItems.filter((item) => {
		return item !== itemId
	    })
	    setSelectedItems(newArr);
	}
	else {
	    setSelectedItems(selectedItems => [...selectedItems, itemId])
	}
    };

    const priceValidation = () => {
        if (senderMoneyOffer === '') {
            return true;
        }

        var regex = new RegExp('^(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?')

        if (!senderMoneyOffer.match(regex)) {
            return false;
        }
        else {
            return true;
        }

    };


    const handleSubmit = async () => {
        setError('');
        if (selectedItems.length === 0 && senderMoneyOffer > 0 && listing.type === 'trade-only') {
            setError('You cannot only send a money offer, this user has set this item to "trade only"');
            return;
        }

        //validate mone
        if (await !priceValidation()) {
            setError('Please enter a valid money offer');
            return;
        }

	if (selectedItems.length === 0 && senderMoneyOffer < 1) {
	    setError('Please select an item or choose a money offer amount');
	}
	else {
            const startTradeData = {productIds: selectedItems, senderId: _id, senderMoneyOffer}

	    startTrade(startTradeData, listing, token, _id).then(async trade => {
                if (trade.error) {
                    setError(trade.error);
                    return;
                }

                await sendNotification(
                    'trade',
                    trade._id, 
                    `${name} has started a trade with you!`, 
                    'Click to view',
                    '',
                    `/trade/${trade._id}`,
                    _id,
                    trade.receiver._id,
                    false
                );
		history.push(`/trade/${trade._id}`)
	    });
	}
    };

    const ShowError = () => (
	<div className='rounded-md bg-red-50 p-2' style={{display: error ? '' : 'none'}}>
            <p className='text-red-500'>{error}</p>
	</div>
    );


    return (
	<Transition.Root show={open} as={Fragment}>
	    <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={handleClose}>
		<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
		    <Transition.Child
			as={Fragment}
			enter="ease-out duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		    >
			<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
		    </Transition.Child>

		    {/* This element is to trick the browser into centering the modal contents. */}
		    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
			&#8203;
		    </span>
		    <Transition.Child
			as={Fragment}
			enter="ease-out duration-300"
			enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			enterTo="opacity-100 translate-y-0 sm:scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 translate-y-0 sm:scale-100"
			leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full sm:p-3">
			    <div>
				<div className="mt-1 text-center sm:mt-5">
				    <div className='flex justify-center'>
					<Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
					    Choose Item(s) to Trade
					</Dialog.Title>
				    </div>
                                        <p className='text-sm text-gray-500'> Or send only a money offer </p>

				    <ShowError />

				    {/* Item grid */}
				    <div className="mt-2">
					<div className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8k overflow-y-scroll h-96 p-3'>
                                            {items.filter(item => item.isVisible && item.isVirtuallyVerified).map((item, i) => (
						<div key={i} onClick={() => handleSelect(item._id)}>
						    <ChooseItemCard item={item} />
						</div>
                                            ))}
					</div>
				    </div>

				</div>
			    </div>

			    {/* MOney offer */}
                            <MoneyOffer senderMoneyOffer={senderMoneyOffer} setSenderMoneyOffer={setSenderMoneyOffer}/>

			    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
				<button
				    type="button"
				    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
				    onClick={handleSubmit}
				>
				    Start Trade
				</button>
				<button
				    type="button"
				    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
				    onClick={handleClose}
				    ref={cancelButtonRef}
				>
				    Cancel
				</button>
			    </div>
			</div>
		    </Transition.Child>
		</div>
	    </Dialog>
	</Transition.Root>
    );
};

export default ChooseItemModal;
