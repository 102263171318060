import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Layout from '../Layout';
import { getProduct, getUserInfo, getMyListedItems, getActiveTrades, updateProduct } from '../../user/apiUser';
import { useParams, Link, useHistory} from 'react-router-dom';
import { StarIcon } from '@heroicons/react/24/solid'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import ChooseItemModal from './ChooseItemModal';
import { isAuthenticated } from '../../auth';
import SignupModal from '../../user/SignupModal';
import SigninModal from '../../user/SigninModal';
import Badges from './Badges';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ButtonOptions from './ButtonOptions';
import LikeProduct from './LikeProduct';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProductPage = () => {
    const { productId } = useParams();
    const history = useHistory();
    const [item, setItem] = useState({secondary_photos:['Loading']}); //change name to product when done
    const [userInfo, setUserInfo] = useState({name: ''});
    const [userRating, setUserRating] = useState({rating: 0, length: 0});
    const [shippingCost, setShippingCost] = useState('Loading Rate');
    const [allPhotos, setAllPhotos] = useState([]);

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateErr, setUpdateErr] = useState('');

    const [editState, setEditState] = useState({
      name: '',
      description: '',
      condition: '',
      preOwnedCondition: '',
      size: '',
      brand: '',
      type: '',
      price: '',
      category: '',
      primary_photo: '',
      secondary_photos: [],
      who_pays: '',
      sellerShippingCost: 0,
      stockxUrlKey: '',
      marketPrice: '',
    });

    //TODO: stockx url key set


    const [liked, setLiked] = useState(false);

    const [token, setToken] = useState('');

    const [isTrading, setIsTrading] = useState(false);

    //Signin/Signup modal states
    const [signupOpen, setSignupOpen] = useState(false);
    const handleSignupClose = () => setSignupOpen(false);
    const handleOpenSignup = () => setSignupOpen(true);

    const [loading, setLoading] = useState(true);
    const [fillShipping, setFillShipping] = useState(false);

    const [error, setError] = useState(false);

    const [userItems, setUserItems] = useState([]);
    const [openItemModal, setOpenItemModal] = useState(false);
    const handleTradeOpen = () => { //getMyListedItems call here
        //{ user, _id } = isAuthenticated();
        if (isAuthenticated()) {
            const { user: {_id}, token } = isAuthenticated();

            getUserInfo(_id).then(myInfo => {
                if (!myInfo.shipping_address.street1) {
                    setFillShipping(true);
                }
                else {
              setOpenItemModal(true);
              getMyListedItems(_id).then(data => {
                  setUserItems(data);
              });

                  }
              });
        }
        else {
            /* Show login modal here */
            setSignupOpen(true);
        }

    };
    const handleClose = () => setOpenItemModal(false);
    
    const [buyOpen, setBuyOpen] = useState(false);
    const handleBuyClose = () => setBuyOpen(false);
    

    const saveEdit = () => {
      const { user: {_id}, token } = isAuthenticated();
      
      var secondary_photos = editState.secondary_photos.trim().replace(/\n/g, '').split(',').map(photo => {return{src: photo.trim()}})
      const prodData = {
        ...editState,
        productIdToUpdate: productId,
        photos: [{src: editState.primary_photo}, ...secondary_photos]
      };
      console.log(prodData)
      updateProduct(productId, _id, token, prodData).then(res => {
        console.log(res)
        if (res?.err) {
          setUpdateErr(res.err);
        } else {
          setUpdateSuccess(true);
        }
      });
    };

    const handleChange = useCallback((e) => {
      const { name, value } = e.target;
      setEditState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }, []);



    const handleBuyOpen = () => {
        if (isAuthenticated()) {
            const { user: { _id } } = isAuthenticated();
            getUserInfo(_id).then(myInfo => {
                if (!myInfo.shipping_address.street1) {
                    setFillShipping(true);
                }
                else {
                    history.push(`/checkout/${item._id}`);
                }
            });
        }
        else {
            //signin thing
            //guest checkout page
            setBuyOpen(true);
        }
    };



    useEffect(() => {
        //get product
        if (isAuthenticated()) {
            setToken(isAuthenticated().token);
        }
        getProduct(productId).then(async data => {
            if (data.error) {
                setLoading(false);
                setError(true);
                return;
            }

            await setItem(data)
            let secondaryPhotosString = data.secondary_photos.join(',\n') 
            setEditState({
              name: data.name,
              description: data.description,
              condition: data.condition,
              preOwnedCondition: data.preOwnedCondition,
              size: data.size,
              brand: data.brand,
              type: data.type,
              price: data.price,
              marketPrice: data?.marketPrice,
              category: data.category,
              primary_photo: data.primary_photo,
              secondary_photos: secondaryPhotosString,
              who_pays: data.who_pays,
              sellerShippingCost: data.sellerShippingCost,
              stockxUrlKey: '',
            });
            
            if (data.who_pays === 'buyer-pays') {
                setShippingCost('$'+data.sellerShippingCost)
            }
            else {
                setShippingCost('FREE shipping');
            }
            var all_photos = await [{
                original: data.primary_photo, 
                thumbnail: data.primary_photo,
                thumbnailWidth: '50px',
                originalClass: 'sm:rounded-lg h-fit object-contain',
                originalHeight: 'height: 50px',
            }];
            await data.secondary_photos.map(photo => {
                all_photos.push({
                    original: photo, 
                    thumbnail: photo, 
                    thumbnailWidth: '50px',
                    originalClass: 'sm:rounded-lg h-fit object-contain',
                    originalHeight: 'height: 50px',
                })
            });

            await setAllPhotos(all_photos);

            //Receiver info (merchant)
            getUserInfo(data.userId).then(user => {
                let sum = 0;
                if (user.ratings.length !== 0) {
                    user.ratings.forEach(rating => {
                        sum = sum + rating.rating
                    });
                    
                    const rating = sum / user.ratings.length;

                    setUserRating({rating: rating, length: user.ratings.length});
                }

                setUserInfo(user);
                setLoading(false);
            });
        });
    },[]);

    const BuyButton = ({disabled}) => (
        <div className="mt-4">
            {!disabled && 
            <>
            <button
                type="submit"
                onClick={handleBuyOpen}
                className="max-w-xs flex-1 bg-black border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:text-black hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-white-500 w-full border-black"
            >
                Buy Now

            </button>
    { fillShipping &&  
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            You need to fill out your shipping info.{' '}
            <a href="/dashboard/address" className="font-medium text-yellow-700 underline hover:text-yellow-600">
              Click here to fill shipping, then return back to this page.
            </a>
          </p>
        </div>
      </div>
    </div>
                }
            </>
            }
        </div>
    );

    const TradeButton = ({disabled}) => (
        <div className="mt-4 flex sm:flex-col1">
            {!disabled && 
            <button
                onClick={handleTradeOpen}
                type="submit"
                className="max-w-xs flex-1 bg-accent-color border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
            >
                Send Offer
            </button>
            }
        </div>
    );


    const UserSection = () => (
        <div className='border-t border-b mt-6'>
            <div className='p-4 mb-1'>
                <a href={`/user-profile/${userInfo._id}`}>
                <img
                    className="h-12 w-12 rounded-full object-cover float-left mr-2"
                    src={userInfo.profile_picture}
                    alt=""
                />
                <p className='text-md col-span-1'><strong>{userInfo.name}</strong></p>

                {userRating.length !== 0 ?
                    <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                                key={rating}
                                className={classNames(
                                    userRating.rating - 1 > rating ? 'text-indigo-500' : 'text-gray-300',
                                    'h-4 w-4 flex-shrink-0'
                                )}
                                aria-hidden="true"
                            />
                        ))}
                        <p className='text-xs text-gray-400 ml-1'>({userRating.length}) Reviews</p>
                    </div>
                    :
                    <div className='flex items-center'>
                        <p className='text-xs text-gray-400'>New Seller. Rating not avaliable</p>
                    </div>
                }
                </a>
            </div>
        </div>   
    );


    const ProductOverview = () => (
        <div className="bg-white min-h-screen min-h-screen mb-12">
            <div className="max-w-2xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                     <ImageGallery 
                        items={allPhotos}
                        showThumbnails={true}
                        showPlayButton={false}
                    />
                    {/* Product info */}
                    <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0 w-full">
                        <div className='flex items-center space-x-4'>
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 underline">
                            {item.brand} 
                            </h1>
                            <LikeProduct 
                                liked={liked} 
                                setLiked={setLiked}
                                productId={productId}
                                handleOpenSignup={handleOpenSignup}
                            />

                        </div>
                        <Badges type={item.type}/>
                        <div className="mt-3 space-y-2 mb-2">
                            <h2 className="sr-only">Product information</h2>
                            <p className='text-sm text-gray-700'>Condition: {item.condition}</p>
                            <p className='text-sm text-gray-700'>{item.name}</p>
                            <p className='text-sm text-gray-700'>Size: {item.size}</p>
                            <p className='text-sm text-gray-700'>
                                Shipping: {shippingCost}
                            </p>
                            { item.type !== 'trade-only' && <p className="text-md pt-2 text-black">${item.price}</p> }

                        </div>

                        <ButtonOptions
                            item={item}
                            isTrading={isTrading}
                            productId={productId}
                            product={item}
                            TradeButton={TradeButton}
                            BuyButton={BuyButton}
                            setSignupOpen={setSignupOpen}
                            
                        />

                        <div className='mt-5 grid-cols-2'>
                            <ShieldCheckIcon className='h-10 w-10 col-span-1 float-left pr-3 text-indigo-600'/>
                            <p className='text-sm col-span-1'> Buyer Protection Guarantee </p>
                            <p className='text-xs text-gray-500 col-span-1'>Purchases are covered by PayPal Purchase Protection</p>
                        </div>


                        <UserSection />

                        <section aria-labelledby="details-heading" className="mt-6">
                            <h2 id="details-heading" className="sr-only">
                                Description
                            </h2>
                            <h2 className='font-semibold'> Description </h2>
                            <p className='text-gray-700'>{item.description}</p>
                        </section>
                        { item.interestedIn &&
                        <section aria-labelledby="details-heading" className="mt-6 pt-3 border-t">
                            <h2 id="details-heading" className="sr-only">
                                Looking for...
                            </h2>
                            <h2 className='font-semibold'> This user is looking for: </h2>
                            <p className='text-gray-700'>{item.interestedIn}</p>
                        </section>
                        }
                    </div>
                </div>
            </div>
        </div> 
    );



    if (loading) {
        return (
            <Layout className='min-h-screen'>
                <p className='text-bold animate-pulsing text-center'>Loading...</p>
            </Layout>
        );
    }

    if (error) {
        return (
        <Layout className='min-h-screen'>
            <p className='text-bold text-center'> Product not found</p>
        </Layout>
        );
    }


    return (
        <div>
            {!isAuthenticated() ?
                <>
                <SignupModal 
                    open={signupOpen} 
                    handleClose={handleSignupClose} 
                    handleOpenSignup={handleOpenSignup}
                    guest={true}
                    className='absolute'
                    />
                <SigninModal
                    open={buyOpen}
                    handleClose={handleBuyClose}
                    handleOpenSignup={handleOpenSignup}
                    guest={true}
                />
                </>
                :

                <ChooseItemModal open={openItemModal} handleClose={handleClose} items={userItems} listing={item}/>
            }
            <Layout>
                <ProductOverview/>
    <>
      <table className="w-full divide-y divide-gray-200">
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.entries(editState).map(([key, value]) => (
          <tr key={key}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{key}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <textarea
                key={key}
                rows={4}
                type="text"
                name={key}
                value={value}
                onChange={handleChange}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <button
        onClick={saveEdit}
        type="button"
        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        SAVE
      </button>
      {updateSuccess && <h1 className='text-green-500'>DONE!</h1>}
      {updateErr && <h1 className='text-green-500'>oh shit: {updateErr}</h1>}
    </>

            </Layout>
        </div>
    );
};

export default ProductPage;
