import React, { useState, useEffect, useRef, Fragment} from 'react';
import { signout, isAuthenticated } from '../../auth';
import notificationSocket from '../notification_socket';
import { getUserInfo, newNotifFalse } from '../../user/apiUser';
import { deleteNotification } from '../apiCore';
import ResendVerifyEmailModal from '../ResendVerifyEmailModal';

import Logo from './Logo';

//tailwind
import { Transition, Disclosure, Menu } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    XMarkIcon,
    InboxIcon,
    CheckCircleIcon,
    MinusCircleIcon,
} from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Inbox / Offers', href: '/trades', current: false },
    { name: 'Orders', href: '/orders', current: false },
];


const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
};

const LoggedInHeader = ({ history, children }) => {
    const [notifications, setNotifications] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const notifRef = useRef();
    notifRef.current = notifications;
    const [newNotif, setNewNotif] = useState(false);

    const [openSignup, setOpenSignup] = useState(false); //signup modal
    const handleOpenSignup = () => setOpenSignup(true);
    const handleCloseSignup = () => setOpenSignup(false);

    const [openSignin, setOpenSignin] = useState(false);
    const handleOpenSignin = () => setOpenSignin(true);
    const handleCloseSignin = () => setOpenSignin(false);

    const [resendOpen, setResendOpen] = useState(false);
    const resendHandleClose = () => setResendOpen(false);

    var { user: { _id }, token } = isAuthenticated();

    useEffect(() => {
        if (!isAuthenticated()) return;

        var { user: { _id }, token } = isAuthenticated();
        getUserInfo(_id).then(userData => {
            setUserInfo(userData);
        });
    },[]);

    const handleNotifClick = (notification) => {
        const content = {title: notification.title, description: notification.description,
            image: notification.image, link: notification.link, uId: notification.uId, dateAdded: notification.dateAdded};
        const { user:{_id}, token } = isAuthenticated(); 

        deleteNotification(content, _id, token).then(async data => {
            const newNotifications = await notifications.filter(notification =>
                notification.uId != content.uId
            );
            if (notifications.length === 1) {
                await console.log(notifications);
            }
            else {
                await setNotifications(newNotifications);
            }
        });;

    };

    const RenderIcon = ({type}) => { //used in notifications
        if (type === 'accepted') {
            return (
                <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            );
        }
        else if (type === 'declined') {
            return ( 
                <MinusCircleIcon className='h-6 w-6 text-red-400' aria-hidden='true'/>
            );
        }
        else {
            return (
                <InboxIcon className='h-6 w-6'/>
            );
        }
    };


    const AddLootCondition = () => {
        if (!userInfo.emailVerified) {
            return (
                <button 
                    onClick={() => setResendOpen(true)}
                    className='inline-flex items-center px-4 sm:px-2 py-1.5 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                    Add Loot
                </button>
            )
        }
        else if (!userInfo.shipping_address.street1) {
            return (
                <Menu as="div" className="relative inline-block text-left z-50">
                    <div>
                        <Menu.Button className='inline-flex items-center px-4 sm:px-2 py-1.5 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                            Add Loot
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/dashboard/address"
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            You must fill out a shipping address to add loot. Click here to fill
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            );

        }
        else {
            return (
                <a href='/add-loot'>
                    <button
                        type='button'
                        className='inline-flex items-center px-4 sm:px-2 py-1.5 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-accent-color hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Add Loot
                    </button>
                </a>
            );
        }
    };

    const SortTime = (date) => {
        const timeSince = (new Date().getTime() - new Date(date).getTime()) / 1000;

        if (Math.floor(timeSince / 60) < 1) {
            return "Now"
        }
        else if (Math.floor(timeSince / 60) < 60) {
            return `${Math.floor(timeSince / 60)}min`;
        } else if (Math.floor(timeSince / 3600) < 24) {
            return `${Math.floor(timeSince / 3600)}hr`;
        } else if (Math.floor(timeSince / 86400) < 32) {
            return `${Math.floor(timeSince / 86400)}d`;
        } else {
            return "Months Ago";
        }

    };

    const SortedNotifications = () => (
        <div className="overflow-y-scroll h-80">
            {notifications && notifications?.map((notification, i) => (
                <Menu.Item key={i}>
                    {({ active }) => (
                        <div>
                            <a
                                href={notification.link}
                                onClick={() => handleNotifClick(notification)}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 z-50'
                                )}
                            >
                                {/* notification widget from tailwind */}
                                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="p-4">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0">
                                                <RenderIcon type={notification.type}/>
                                            </div>
                                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                                <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                                                <span className="mt-1 text-sm text-gray-500 pr-2">{notification.description}</span>
                                                -
                                                <span className="pl-2 text-sm font-bold text-gray-500">
                                                    {SortTime(notification.dateAdded)}
                                                </span>
                                            </div>
                                            <div className="ml-4 flex-shrink-0 flex">
                                                {/* X icon went here */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </Menu.Item>
            ))}

        </div>
    );

    const UserNotifications = () => (
        <>
            <div>
                <Menu.Button className='bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    <BellIcon 
                        className="h-6 w-6" 
                        aria-hidden="true" 
                        onClick={() => {
                            if (newNotif) {
                                setNewNotif(false)
                                newNotifFalse(_id, token);
                            }
                        }}
                    />
                    {newNotif ?
                        <span class="absolute bg-red-600 h-2 w-2 rounded-full -top-0 -right-0 animate-ping" />
                        :
                        <p></p>
                    }
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-11/12 md:w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">

                    {notifications && notifications?.length === 0
                        ?
                            <Menu.Item>
                                {({ active }) => (

                                    <div className="h-15 sm:h-10">
                                        <p
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700 sm:z-50 text-center'
                                            )}
                                        >
                                            No new notifications. Check back later!
                                        </p>
                                    </div>
                                )}
                            </Menu.Item>
                            :

                            <SortedNotifications/>
                    }


                </Menu.Items>
            </Transition>  
        </>     
    );

    return (
        <div className="min-h-full sticky top-0 z-30">
            <ResendVerifyEmailModal
                open={resendOpen}
                handleClose={resendHandleClose}
                email={userInfo.email}
                userId={_id}
                token={token}
            />
            <Disclosure as="nav" className="bg-white border-b border-gray-200">
                {({ open }) => (
                    <>
                        <div className="mx-auto px-4 sm:px-6">
                            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6  md:space-x-10" >
                                <div className="flex justify-start lg:w-0 lg:flex-1">
                                    <Logo classNames={'h-8 w-auto lg:pr-8 mt-2'}/>
                                    <div className='lg:w-9/12'>
                                        {children}
                                    </div>
                                </div>




                                <div className="hidden sm:my-px sm:flex sm:items-center float-right">
                                    {/* Navigation */}
                                    <div className='space-x-5 sm:flex sm:-my-px'>
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'border-indigo-500 text-gray-900'
                                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                        <AddLootCondition/>
                                    </div> 


                                    {/*Notifications dropdown */}
                                    <Menu as='div' className='ml-3 relative'>
                                        <UserNotifications/>
                                    </Menu>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                <span className="sr-only">Open user menu</span>
                                                <img className="h-8 w-8 object-cover rounded-full" src={userInfo.profile_picture} alt="" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href='/dashboard/profile'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Dashboard
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href='/dashboard/address'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Address
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href='/dashboard/products'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            My Loot
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href='/dashboard/archive'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Archive
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href='/dashboard/wallet'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Wallet
                                                        </a>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                            onClick={
                                                                () => signout(() => {
                                                                    history.push('/');
                                                                })}
                                                        >
                                                            Signout
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>

                                <div className="-mr-2 flex  sm:hidden">
                                    {/* Mobile Notifications Dropdown */}
                                    <span className="sr-only">View notifications</span>
                                    <Menu as='div' className='ml-3 md:relative'>
                                        <UserNotifications></UserNotifications> 
                                    </Menu>


                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="bg-white inline-flex items-center justify-center rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <div>
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            </div>
                                        )}
                                    </Disclosure.Button>


                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="pt-2 pb-3 space-y-1 justify-center">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className={classNames(
                                            item.current
                                                ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}

                                <AddLootCondition/>
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-200">
                                <div className="flex items-center px-4">
                                    <div className="flex-shrink-0">
                                        <img className="h-10 w-10 object-cover rounded-full" src={userInfo.profile_picture} alt="" />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">{userInfo.name}</div>
                                        <div className="text-sm font-medium text-gray-500">{userInfo.email}</div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1">
                                    <Disclosure.Button
                                        as="a"
                                        href='/dashboard/profile'
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Dashboard
                                    </Disclosure.Button>

                                    <Disclosure.Button
                                        as="a"
                                        href='/dashboard/address'
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Address
                                    </Disclosure.Button>

                                    <Disclosure.Button
                                        as="a"
                                        href='/dashboard/products'
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        My Loot
                                    </Disclosure.Button>
                                    
                                    <Disclosure.Button
                                        as="a"
                                        href='/dashboard/archive'
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Archive
                                    </Disclosure.Button>

                                    <Disclosure.Button
                                        as="a"
                                        href='/dashboard/wallet'
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        Wallet
                                    </Disclosure.Button>

                                    <Disclosure.Button
                                        as="a"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    >
                                        <a
                                            onClick={
                                                () => signout(() => {
                                                    history.push('/');
                                                })}
                                        >
                                            Signout
                                        </a> 
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );

};

export default LoggedInHeader;
