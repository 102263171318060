export const API = process.env.REACT_APP_API_URL;
export const SOCKET_API = process.env.REACT_APP_SOCKET_URL;
export const SHIPPO_API = process.env.REACT_APP_SHIPPO_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const APP_URL = process.env.REACT_APP_URL;
export const GOOGLE_API = process.env.REACT_APP_GOOGLE_API;
export const ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME;
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY;
