import React, { useEffect, useState } from 'react';
import Layout from '../../core/Layout';
import { isAuthenticated } from '../../auth';
import { searchesWithSize } from '../apiAdmin';
import { CSVLink } from 'react-csv';

// TODO:
//      - Notify each user about newly uploaded items  
//              (be sure to handle not sending multiple notifs)

const UserSearches = () => {
  const [searches, setSearches] = useState([]);
  const { token, user: { _id } } = isAuthenticated();

  useEffect(() => {
    searchesWithSize(_id, token)
      .then(data => {
        setSearches(data);
      });
  }, []);

  const headers = [
    { label: "Search Query", key: "query" },
    { label: "User Name", key: "userName" },
    { label: "Shoe Size", key: "shoeSize" },
    { label: "Tops Size", key: "topsSize" },
    { label: "Bottoms Size", key: "bottomsSize" },
    { label: "Date ", key: "createdAt" }
  ];

  const csvReport = {
    filename: 'UserSearchesReport.csv',
    headers: headers,
    data: searches.map(search => ({
      query: search.query,
      userName: search.userId?.name,
      shoeSize: search.userId?.onboardingData?.shoeSize,
      topsSize: search.userId?.onboardingData?.topsSize,
      bottomsSize: search.userId?.onboardingData?.bottomsSize,
      createdAt: search.createdAt,
    })),
    delimiter: ","
  };

  if (!searches.length) {
    return <Layout><p>Loading...</p></Layout>;
  }

  return (
    <Layout>
      <div>
        <CSVLink {...csvReport} className="mb-3 border border-w-5 border-gray-600">Download CSV</CSVLink>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header.key} className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {searches.map((search, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search.query}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search.userId?.name}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search.userId?.onboardingData?.shoeSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search.userId?.onboardingData?.topsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search.userId?.onboardingData?.bottomsSize}</td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{search?.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default UserSearches;

