import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ProductShare = () => {
  const search = useLocation().search;
  const redirectUrl = new URLSearchParams(search).get('url');
  const productName = new URLSearchParams(search).get('productName');
  const productPhoto = new URLSearchParams(search).get('productPhoto');

  useEffect(() => {
    console.log(redirectUrl);
    // TODO: truncate product photo
    console.log(productName);
    console.log(productPhoto);
  }, []);

  return (
    <>
      <Helmet>
        <title>{productName}</title>
        <meta property="og:title" content={productName} />
        <meta property="og:image" content={productPhoto} />
      </Helmet>
    </>
  );
};

export default ProductShare;
