import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';

import { Tab } from '@headlessui/react'
import { motion, AnimatePresence } from "framer-motion"
import mocaLows from '../images/moca-lows.webp'
import ysl from '../images/ysl.webp'
import redOctobers from '../images/red.webp'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const SlideOne = ({selectedIndex}) => (
    <div className='relative'>
        <main className='lg:relative'>
            <div className="mx-auto w-full max-w-7xl lg:py-48 text-center md:text-left">
                <div className="relative w-full h-48 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2 items-center">
                    <img
                        className="absolute inset-0 h-full w-full object-cover "
                        src={mocaLows}
                        alt=""
                    />
                </div>


                <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
                    <h1 className="text-xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                        <span className="block xl:inline">Swap Clothing and Sneakers</span>{' '}
                        <span className="block text-indigo-600 xl:inline ">Safely and Securely</span>
                    </h1>
                    <p className="mx-auto mt-3 max-w-md text-sm md:text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                        All items are <p className='text-indigo-600'>thoroughly authenticated </p> 
                    </p>
                </div>
            </div>
        </main>
    </div>

);

const SlideTwo = ({selectedIndex}) => (
    <div className="relative">
        <main className="lg:relative">
            <div className="mx-auto w-full max-w-7xl  lg:py-48 text-center md:text-left md:left">
                <div className="relative w-full h-48 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2 items-center">
                    <img
                        className="absolute inset-0 h-full w-full object-cover "
                        src={ysl}
                        alt=""
                    />
                </div>


                <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
                    <h1 className="text-xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                        <span className="block xl:inline mt-3 md:mt-1">Zero seller fees for a limited time </span>{' '}
                    </h1>
                    <p className="mx-auto max-w-md text-lg md:text-2xl sm:text-xl  md:max-w-3xl">
                        <p className='text-green-600'> lootswap: 0% </p>

                    </p>
                </div>
            </div>
        </main>
    </div>



);

const SlideThree = ({selectedIndex}) => (
    <div className="relative">
        <main className="lg:relative">
            <div className="mx-auto w-full max-w-7xl text-center lg:py-48 lg:text-left">
                <div className="relative h-48 w-full  md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={redOctobers}
                        alt=""
                    />
                </div>


                <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
                    <h1 className="text-xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                        <span className="block xl:inline">Join our </span>{' '}
                        <span className="block text-indigo-600 xl:inline">Discord community</span>
                    </h1>
                    <p className="mx-auto mt-1 max-w-md text-md md:text-lg text-blue-500 sm:text-xl md:mt-5 md:max-w-3xl">
                        <a href='https://discord.gg/z6EH4zAfxt'> https://discord.gg/z6EH4zAfxt </a>
                    </p>
                </div>
            </div>
        </main>
    </div>


);



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const HeroSection = () => {
    const tabs = [0,1,2];
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [autoSlide, setAutoSlide] = useState(true);
    const [timeoutId, setTimeoutId] = useState('');


    return (
    
        <Carousel className='mb-3 md:mb-1 md:max-h-[31rem] md:min-h-[31rem] max-h-[19rem] min-h-[19rem]' responsive={responsive}>
            <SlideOne />
            <SlideTwo/>
            <SlideThree />
        </Carousel>
    );

};

export default HeroSection;
