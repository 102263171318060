import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { API } from '../config';
import { signUp } from '../auth';
import EmailValidator from 'email-validator';
import { XCircleIcon } from'@heroicons/react/24/solid';
import SuccessModal from '../core/SuccessModal';
import { GOOGLE_CLIENT_ID } from '../config';
import GoogleLogin from 'react-google-login';
import { handleGoogleLogin, authenticate, isAuthenticated } from '../auth';
import SigninModal from './SigninModal';
import { getSignedRequest, uploadFile } from './add_product/apiProduct';
import loadingGif from '../images/loading_gif.gif';
import ReCAPTCHA from 'react-google-recaptcha';
import imageCompression from 'browser-image-compression';
//import for QuestionMarkCircleIcon
import { EyeSlashIcon } from '@heroicons/react/20/solid'
import { EyeIcon } from '@heroicons/react/20/solid'

import { useAnalyticsContext } from '../FirebaseContext';
import { logEvent } from 'firebase/analytics';

import { CAPTCHA_KEY } from '../config';

const SignupModal = ({ open, handleClose, handleOpenSignup, guest=false }) => {
    const cancelButtonRef = useRef(null);
    const [successOpen, setSuccessOpen] = useState(false);
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    const [loading, setLoading] = useState(false);

    const [openSignin, setOpenSignin] = useState(false);

    const handleOpenSignin = () => {
        handleClose();
        setOpenSignin(true);
    };
    const handleCloseSignin = () => setOpenSignin(false);

    const [values, setValues] = useState({
        name: '',
        email: '',
        profile_picture: '',
        password: '',
        confirm_password: '',
        showPassword: false,
        captchaToken: '',
        error: '',
        success: false,
        redirectToReferrer: false,
    });

    const [captchaPass, setCaptchaPass] = useState(false);

    const analytics = useAnalyticsContext();



    const { name, email, profile_picture, password, confirm_password, captchaToken, showPassword, success, error, redirectToReferrer } = values;

    const init = async () => {
        const seed = await Math.floor(Math.random() * 999999);
        const url = await `https://avatars.dicebear.com/api/micah/${seed}.png`
        await setValues({...values, profile_picture: url})
    };

    useEffect(() => {
        init();
    },[]);


    const handleChange = name => event => {
        const value = name === 'profile_picture' ? event.target.files[0] : event.target.value;
        if (name === 'profile_picture') {
            setValues({...values, 'profile_picture': loadingGif});

            const options = {
                maxWidthOrHeight: 300,
                useWebWorker: true,
            };
            imageCompression(value, options).then(compressedImage => {
                console.log('compresedata', compressedImage)
                getSignedRequest(compressedImage).then(res => {
                    if (res.error) {
                        setValues({...values, error: 'There was an error uploading your profile picture', success: false});
                    }
                    else {
                        uploadFile(compressedImage, res.signedRequest, res.url).then(imgUrl => {
                            setValues({...values, 'profile_picture': imgUrl});
                        });
                    }
                });

            });


        }
        else {
            setValues({...values, [name]: value})
        }
    };

    const onCaptchaChange = (value) => {
        setValues({...values, 'captchaToken': value});
    };

  const handlePasswordShow = () => setValues({...values, showPassword: !showPassword});
   
    const handleValidation = () => {
        if (!name) {
            setValues({...values, error: 'Username cannot be empty', success: false});
            return false;
        }
        else {
            if (!name.match('^[a-zA-Z0-9 ]{4,15}$')) { //no special characters
                setValues({...values, error: 'Username cannot contain special characters and must be 4-15 characters long', success: false});
                return false;
            }
        }
        if (!email) {
            setValues({...values, error: 'Email cannot be empty', success: false});
            return false;
        }
        else {
            if (!EmailValidator.validate(email)) { //I know im a poser :( 
                setValues({...values, error: 'Email must be valid', success: false});
                return false;
            }
        }
        if (!profile_picture) {
            setValues({...values, error: 'Please select a profile picture (or choose a random one)', success: false});
            return false;
        }
        if (!password) {
            setValues({...values, error: 'Password cannot be empty', success: false});
            return false;
        }
        else {

            if (password.length < 8) {
                setValues({...values, error: `Password must be at least 8 characters long`, success: false});
                return false;
            }

        }

        if (password !== confirm_password) {
                setValues({...values, error: `Passwords must match`, success: false});
                return false;
        }



        return true;
    };

    const clickSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        if (!handleValidation()) {
            setLoading(false);
            return
        }


        setValues({...values, error: ''});
        signUp({name, email, profile_picture, password, captchaToken})
            .then(data => {
                if(data.error) {
                    setLoading(false);
                    if (data.error.includes('email')) {
                        setValues({...values, error: 'Account with this email already exists', success:false});
                    }
                    else if (data.error.includes('name')) {
                        setValues({...values, error: 'Username already taken', success: false});
                    }
                    else {
                        setValues({...values, error: data.error, success: false});
                    }
                }
                else {
                    handleClose();
                    authenticate(data, () => {
                        setValues({
                            ...values,
                            name: '',
                            email: '',
                            profile_picture: '',
                            password: '',
                            error: '',
                            success: true,
                            redirectToReferrer: true,
                        });

                    });
                    //handleSuccessOpen();
                    setValues({
                        ...values,
                        name: '',
                        email: '',
                        profile_picture: '',
                        password: '',
                        error: '',
                        success: true,
                        redirectToReferrer: true,
                    });

                    //google analytics
                    logEvent(analytics, 'sign_up');
                }
            });
    };

    const generateSeed = () => {
        const seed = Math.floor(Math.random() * 999999);
        const url = `https://avatars.dicebear.com/api/micah/${seed}.png`
        setValues({...values, profile_picture: url})
    }; 
    const showError = () => (
        <div className="rounded-md bg-red-50 p-4" style={{display: error ? '' : 'none'}}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <div className="mt-2 text-sm text-red-700">
                        <p className='pl-5 space-y-1'> {error} </p>
                    </div>
                </div>
            </div>
        </div>

    );

    const redirectUser = () => {
        if (redirectToReferrer) {
            return <Redirect to='/'/>
        }
        if (isAuthenticated()) {
            return <Redirect to='/'/>
        }
    };

    const googleSignin = googleData => {
        handleGoogleLogin(googleData).then(data =>  {
            if (data.error) {
                setValues({...values, error: true, success: false})
            }
            else {
                authenticate(data, () => {
                    setValues({...values, redirectToReferrer: true});
                });
            }

        })
    };

    const responseFacebook = response => {
        console.log(response);
    };

    const SigninWith = () => ( //NO longer used
        <div className="mt-4">
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
            </div>

            <div className='mt-1'>
                <GoogleLogin
                    clientId={GOOGLE_CLIENT_ID}
                    onSuccess={googleSignin}
                    cookiePolicy={'single_host_origin'}
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >

                </GoogleLogin>
            </div>
            {/*
                        <FacebookLogin
                            appId='1306228819798690'
                            autoLoad={true}
                            fields='name,email,picture'
                            callback={responseFacebook}
                        />
                        */}
        </div>
    );


    return (
        <>
            <SigninModal open={openSignin} handleClose={handleCloseSignin} handleOpenSignup={handleOpenSignup}/>
            <form className='z-50 absolute'>
                <script src="https://apis.google.com/js/platform.js" async defer></script>
                <SuccessModal 
                    title='Success!'
                    description='You have successfully created an account'
                    open={successOpen}
                    handleClose={handleSuccessClose}
                />
                {redirectUser()}
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={handleClose}>
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white rounded-lg px-8 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 ">
                                    <div>
                                        <div className="mt-1 text-center sm:mt-2">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                Create an Account
                                            </Dialog.Title>
                                            <div className="">
                                                <p className="text-sm text-gray-500">
                                                    By creating an account you will be able to post listings, trade items, and more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {showError()}

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-2">
                                            Email address
                                        </label>
                                        <div className="">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                onChange={handleChange('email')}
                                                autoComplete="email"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 mt-2">
                                            Username
                                        </label>
                                        <div className="">
                                            <input
                                                id="username"
                                                onChange={handleChange('name')}
                                                name="username"
                                                type="text"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-2">
                                            Password
                                        </label>
                                        <div className="relative mt-1 rounded-md shadow-sm">
                                            <input
                                                id="password"
                                                name="password"
                                                onChange={handleChange('password')}
                                                type={showPassword ? "text" : "password"}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                                                <button
                                                    onClick={handlePasswordShow}
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true" 
                                                >
                                                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-2">
                                            Confirm Password
                                        </label>
                                        <div className="relative mt-1 rounded-md shadow-sm">
                                            <input
                                                id="confirm_password"
                                                name="conrim_password"
                                                onChange={handleChange('confirm_password')}
                                                type={showPassword ? "text" : "password"}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
                                                <button
                                                    onClick={handlePasswordShow}
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                >
                                                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='grid-cols-3'>
                                        <label htmlFor="photo" className="block text-sm font-medium text-gray-700 mt-2">
                                            Profile Picture
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <div className="flex items-center">
                                                <span className="h-14 w-14 rounded-full overflow-hidden bg-gray-100">
                                                    <img src={profile_picture} alt='profile picture' className='object-cover'/>
                                                </span>
                                                <label
                                                    htmlFor='pfp_upload'
                                                >
                                                    <span
                                                        htmlFor='pfp_upload'
                                                        className='col-span-1 ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                                                        Choose File
                                                    </span>

                                                    <input 
                                                        id='pfp_upload'
                                                        name='pfp_upload'
                                                        type='file'
                                                        accept='/image/*'
                                                        onChange={handleChange('profile_picture')}
                                                        className='sr-only'
                                                    />
                                                </label>


                                            </div>
                                        </div>
                                        <button onClick={generateSeed}><p className='text-xs ml-1 text-blue-600'> Random </p> </button>
                                    </div>


                                        <ReCAPTCHA
                                            sitekey={CAPTCHA_KEY}
                                            onChange={onCaptchaChange}
                                            className='mt-2'
                                            size='small'

                                        />
                      <div className="flex items-center ">

                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 text-xs">
                            By creating an account you agree to our <a href='/TOS' className='text-blue-600'> Terms of Service </a> and <a href='/privacy' className='text-blue-600'> Privacy Policy </a>
                        </label>
                      </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-1 sm:grid-flow-row-dense">

                                        { loading ?

                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm animate-pulse"
                                        >
                                            Creating Account...
                                        </button>

                                        :

                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                            onClick={clickSubmit}
                                        >
                                            Signup
                                        </button>
                                        }
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                            onClick={handleClose}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>

                                        <div className='text-sm mt-1'>
                                            <button  onClick={handleOpenSignin} className='font-medium text-indigo-600 hover:text-indigo-500'>
                                                or Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </form>
        </>
    );
};

export default SignupModal;
